import React, { useEffect, useState, useRef } from 'react';
import { setTitleInFirebase, subscribeToTitleInFirebase } from '../Firebase';
import { UserLogo } from './SVGs';
import { onAuthStateChanged } from 'firebase/auth';
import { getAuth } from 'firebase/auth';
import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';

export function TopBar(props) {
  const { bookNoteId, showAppButton } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const [currTitle, setCurrTitle] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [userPhotoURL, setUserPhotoURL] = useState(null); // [TODO
  const [name, setName] = useState(null);

  const inputRef = useRef(null);

  const auth = getAuth();
  // get user name from firebase
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setName(user.displayName);
      // get user's profile photo
      if (user.photoURL) {
        setUserPhotoURL(user.photoURL);
      }
    } else {
    }
  });

  const setTitle = (newTitle) => {
    // drop outside spaces
    if (newTitle === undefined || newTitle === null) {
      console.log('newTitle is undefined or null');
      setIsEditing(false);
      return;
    }
    if (newTitle === '') {
      console.log('newTitle is empty');
      setEditedTitle(currTitle);
      setIsEditing(false);
      return;
    }
    const titleToBeUsed = newTitle.trim();
    setTitleInFirebase(bookNoteId, titleToBeUsed);
    setIsEditing(false);
  };

  useEffect(() => {
    if (bookNoteId === null || bookNoteId === '') {
      return;
    }
    subscribeToTitleInFirebase(bookNoteId, (newTitle) => {
      console.log('firebase title change');
      setCurrTitle(newTitle);
      setEditedTitle(newTitle);
    });
  }, [bookNoteId]);

  // useEffect(() => {
  //   if (title !== currTitle) {
  //     setTitle(title);
  //     setEditedTitle(title);
  //   }
  // }, [title]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing]);

  const handleTitleClick = () => {
    setIsEditing(true);
    // select the text inside
  };

  const handleTitleChange = (e) => {
    setEditedTitle(e.target.value);
  };

  const handleTitleBlur = () => {
    setTitle(editedTitle); // Assuming `setTitle` updates the title state or prop.
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setTitle(editedTitle); // Assuming `setTitle` updates the title state or prop.
    }
  };

  return (
    <div className='border-b border-gray-300 w-full h-12 mt-1  px-1 flex flex-row items-center justify-center'>
      <Link className='-mt-1' to='/'>
        <span className='text-4xl cursor-pointer select-none mt-1 text-gray-800 '>
          Book
        </span>
        <span className='text-4xl cursor-pointer select-none mt-1 text-red-400 '>
          Note
        </span>
      </Link>
      <div className='flex-grow'></div>
      {showAppButton && (
        <div>
          <Link
            to={`/${bookNoteId}`}
            className='mx-2 hover:bg-gray-200 bg-gray-100 rounded-md p-2 text-sm'
          >
            App View
          </Link>
        </div>
      )}
      <Menu>
        <Menu.Button className='h-10 w-10 p-1 rounded-full m-1 hover:bg-gray-200 focus:outline-none'>
          {userPhotoURL ? (
            <img
              className='h-full w-full rounded-full object-cover'
              src={userPhotoURL}
              alt='user'
            />
          ) : (
            <UserLogo />
          )}
        </Menu.Button>

        {
          <Menu.Items
            className='absolute focus:outline-none right-0 z-40 mr-3 mt-12 top-0 w-40 border bg-white border-gray-300 rounded-lg shadow-lg'
            onBlur={() => {
              setShowDropdown(false);
            }}
          >
            <div className='flex flex-col'>
              {/* <Menu.Item>
                {({ active }) => (
                  <li
                    className={`border-b text-left flex flex-col justify-center h-8 px-2 hover:bg-gray-200 cursor-pointer rounded-t-lg select-none ${
                      active ? 'bg-gray-200' : ''
                    }`}
                  >
                    <span
                      className='text-gray-900'
                      onClick={() => {
                        window.location.href = '/dashboard';
                      }}
                    >
                      Dashboard
                    </span>
                  </li>
                )}
              </Menu.Item> */}
              {name && (
                <Menu.Item>
                  <span className='text-gray-600 font-semibold py-1 h-8 px-2 rounded m-1 text-center bg-gray-100'>
                    {name}
                  </span>
                </Menu.Item>
              )}
              {auth.currentUser ? (
                <>
                  <Menu.Item>
                    {({ active }) => (
                      <li
                        className={`pb-2 pt-1 border-t h-8 px-2 flex flex-col justify-center hover:bg-gray-200 cursor-pointer select-none ${
                          active ? 'bg-gray-200' : ''
                        }`}
                      >
                        <span
                          className='text-gray-900'
                          onClick={() => {
                            auth.signOut();
                            window.location.href = '/';
                          }}
                        >
                          Log out
                        </span>
                      </li>
                    )}
                  </Menu.Item>
                </>
              ) : (
                <>
                  <Menu.Item>
                    {({ active }) => (
                      <li
                        className={`py-1 h-8 px-2 flex flex-col justify-center hover:bg-gray-200 cursor-pointer select-none ${
                          active ? 'bg-gray-200' : ''
                        }`}
                      >
                        <span
                          className='text-gray-900'
                          onClick={() => {
                            window.location.href = '/login';
                          }}
                        >
                          Login
                        </span>
                      </li>
                    )}
                  </Menu.Item>
                </>
              )}
            </div>
          </Menu.Items>
        }
      </Menu>

      {currTitle !== '' && (
        <div className='absolute '>
          {isEditing ? (
            <input
              ref={inputRef}
              value={editedTitle}
              onChange={handleTitleChange}
              onBlur={handleTitleBlur}
              onKeyDown={handleKeyDown}
              className='w-min text-2xl font-light border px-1 border-gray-400 rounded cursor-text text-center my-1 text-gray-500 focus:outline-none '
              autoFocus
            />
          ) : (
            <span
              className={`text-2xl font-light  cursor-default select-none my-1 text-gray-500`}
              onClick={handleTitleClick}
            >
              {currTitle}
            </span>
          )}
        </div>
      )}
    </div>
  );
}
