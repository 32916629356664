import React from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  getAllBookNotesFromFirebase,
  getBookNotesByAuthor,
  writeBookNoteToFirebase,
  createInitialCellFromPrompt,
  generateRandomHashUUID,
} from '../Firebase';
import { TopBar } from '../components/TopBar';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { LoadingPage } from './LoadingPage';
import InputBox from '../components/InputBox';
import { BNCard } from '../components/BNCard';

import {
  GoogleAuthProvider,
  linkWithCredential,
  signInWithPopup,
} from 'firebase/auth';

const SELECTED_TAB_CLASS =
  'cursor-default   text-left whitespace-nowrap text-lg text-gray-900';
const NOT_SELECTED_TAB_CLASS =
  'cursor-pointer  text-left whitespace-nowrap text-lg  text-gray-300 hover:text-gray-500';

export default function Dashboard() {
  const [myBookNotes, setMyBookNotes] = React.useState({});
  const [topBookNotes, setTopBookNotes] = React.useState({});
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [currTab, setCurrTab] = React.useState('topBookNotes'); // ['myBookNotes', 'topBookNotes'

  const navigate = useNavigate();

  useEffect(() => {
    getAllBookNotesFromFirebase().then((topBookNotes) => {
      setTopBookNotes(topBookNotes);
      setIsLoaded(true);
    });

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        getBookNotesByAuthor(user.uid).then((myBookNotes) => {
          setMyBookNotes(myBookNotes);
          setIsLoaded(true);
        });
      }
    });
  }, []);

  const onCreateBookNote = (prompt) => {
    console.log('onCreateBookNote');
    const auth = getAuth();
    const user = auth.currentUser;

    console.log('user', user);

    if (user) {
      const authorId = user.uid;
      const bookNoteId = generateRandomHashUUID();
      const initialCell = createInitialCellFromPrompt(prompt);
      writeBookNoteToFirebase(bookNoteId, [initialCell], authorId);
      navigate(`/editor/${bookNoteId}/run`);
    } else {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      signInWithPopup(auth, provider)
        .then((results) => {
          const user = results.user;
          console.log('user', user);
          const authorId = user.uid;
          const bookNoteId = generateRandomHashUUID();

          const initialCell = createInitialCellFromPrompt(prompt);
          writeBookNoteToFirebase(bookNoteId, [initialCell], authorId);
          navigate(`/editor/${bookNoteId}/run`);
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });
    }
  };

  return isLoaded ? (
    <div className='h-screen w-screen flex-col flex items-center  select-none bg-white '>
      <div className='h-12 w-full items-center flex flex-row  pb-1 '>
        <TopBar bookNoteId='' />
      </div>
      <div className='overflow-y-scroll w-full'>
        <div className=' flex flex-col w-full pt-2 pb-2 items-center  px-6 sm:px-16 lg:px-32 xl:px-64 bg-gradient-to-r from-gray-50 to-gray-100 '>
          <div className='mt-20 mb-6 md:mb-10 md:text-6xl text-4xl  bg-clip-text text-transparent  bg-gradient-to-l from-red-500 to-purple-500'>
            Prompt to create a web app
          </div>
          <div className=' w-full  mb-28 bg-gradient-to-l from-red-500 to-purple-500 bg-clip-border rounded-full  p-0.5'>
            <InputBox
              cells={[]}
              addCell={() => {}}
              currIndex={0}
              onCreate={onCreateBookNote}
              addCodeCell={() => {}}
              askGPT={() => {}}
              bookNoteId={''}
              loadFile={() => {}}
              setCurrIndex={() => {}}
              handleKeyDown={() => {}}
              userProfileURL={user ? user.photoURL : null}
              className='w-full rounded-full border-transparent  text-black border shadow'
            />
          </div>
        </div>
        <div className=' flex flex-col w-full pt-2 pb-2 border-t border-gray-300  items-center  px-16 sm:px-32 lg:px-48 xl:px-64'>
          {/* tabs */}

          {/* <div className='w-full flex-row flex'> */}
          <div className=' flex flex-row  border border-gray-300 shadow px-3 mt-2 py-2 rounded space-x-1.5 mb-4 '>
            <div
              className={
                currTab === 'topBookNotes'
                  ? SELECTED_TAB_CLASS
                  : NOT_SELECTED_TAB_CLASS
              }
              onClick={() => {
                if (currTab !== 'topBookNotes') {
                  setCurrTab('topBookNotes');
                }
              }}
            >
              Top Booknotes
            </div>
            <div className='h-full border-l border-gray-300'></div>
            <div
              className={
                currTab === 'myBookNotes'
                  ? SELECTED_TAB_CLASS
                  : NOT_SELECTED_TAB_CLASS
              }
              onClick={() => {
                if (currTab !== 'myBookNotes') {
                  setCurrTab('myBookNotes');
                }
              }}
            >
              My Booknotes
            </div>
          </div>
          {/* <div className='flex-grow h-full  '></div> */}
          {/* </div> */}

          {Object.entries(
            currTab === 'myBookNotes' ? myBookNotes : topBookNotes
          ).length === 0 ? (
            <div className='text-gray-300 p-2 rounded  text-center text-xl flex flex-row justify-center h-64'>
              <span>Your booknotes will show here</span>
            </div>
          ) : (
            <div className='grid gap-3  lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2  items-center w-full  '>
              {Object.entries(
                currTab === 'myBookNotes' ? myBookNotes : topBookNotes
              ).map((entry) => {
                const booknoteId = entry[0];
                const bookNote = entry[1];
                if (!Object.keys(bookNote).includes('cells')) {
                  return null;
                }
                return (
                  <BNCard
                    key={booknoteId}
                    bookNoteTitle={bookNote.title}
                    bookNoteId={booknoteId}
                  />
                );
              })}
            </div>
          )}

          <div className='mt-5 -mb-2 pb-3 pt-3 border-t border-gray-300 bg-gradient-to-r from-gray-50 to-gray-100   w-screen flex flex-row justify-center'>
            <div>
              Created by{' '}
              <a
                href='https://twitter.com/behzadhaghgoo'
                className='text-red-500 hover:text-red-700'
              >
                Behzad Haghgoo
              </a>{' '}
              and{' '}
              <a
                href='https://twitter.com/googrish'
                className='text-red-500 hover:text-red-700 '
              >
                Girish Kumar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <LoadingPage />
  );
}
