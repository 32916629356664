import React, { useRef, useEffect } from 'react';

const PixelCanvas = ({ canvasData }) => {
  let pixelSize = 1;
  let { width, height, pixels } = canvasData;
  const canvasRef = useRef(null);
  console.log('pixels', pixels)
  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.height = height;
    canvas.width = width;
    const ctx = canvas.getContext('2d');
    if (ctx === null) {
        console.error('Cannot retrieve get 2d context for canvas');
        return;
    }
    const image = new ImageData(new Uint8ClampedArray(pixels), width, height);
	ctx.putImageData(image, 0, 0);
  }, [width, height, pixelSize, pixels]);

  return <canvas ref={canvasRef} width={width * pixelSize} height={height * pixelSize} />;
};

export default PixelCanvas;