export function convertListToOrderedDict(list) {
  const dict = {};
  for (let i = 0; i < list.length; i++) {
    dict[i] = list[i];
  }
  return dict;
}

export function convertOrderedDictToList(dict) {
  const list = [];
  for (let i = 0; i < Object.keys(dict).length; i++) {
    list.push(dict[Object.keys(dict)[i]]);
  }
  return list;
}

// right now the random hashes might collide
export function generateBookNoteHash() {
  return Math.random().toString(36).substring(2, 15);
}
