import React from 'react';

export default function Tabs(props) {
  const { currTab, setCurrTab, hideCodeFunc } = props;

  const selectedTabClass =
    'inline-block px-4 py-1 text-red-600 border-b-2 border-red-600 rounded-t-lg active dark:text-red-500 dark:border-red-500 cursor-default';
  const unselectedTabClass =
    'inline-block px-4 py-1 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 cursor-default';
  return (
    <div className=' text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700'>
      <ul className='flex flex-row -mb-px '>
        <li className='mr-2 flex flex-row overflow-x-scroll'>
          <span
            className={currTab === 'py' ? selectedTabClass : unselectedTabClass}
            onClick={() => {
              setCurrTab('py');
            }}
          >
            Python
          </span>
          <span
            className={currTab === 'js' ? selectedTabClass : unselectedTabClass}
            onClick={() => {
              setCurrTab('js');
            }}
          >
            Javascript
          </span>
        </li>

        <div className='flex-grow'></div>
        <li className='-mr-2 pt-1'>
          <span
            className="'inline-block px-4 py-1 rounded-t-lg  hover:text-gray-600 whitespace-nowrap  cursor-pointer select-none"
            onClick={() => {
              hideCodeFunc();
            }}
          >
            Hide Code
          </span>
        </li>
      </ul>
    </div>
  );
}
