import { initializeApp } from 'firebase/app';
import wordGenerator from './utils/WordGenerator.js';
import {
  getDatabase,
  ref,
  set,
  onValue,
  get,
  query,
  orderByChild,
  increment,
  update,
  limitToLast,
  limitToFirst,
  equalTo,
  push,
} from 'firebase/database';
import {
  getStorage,
  ref as sRef,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';
import {
  convertListToOrderedDict,
  convertOrderedDictToList,
} from './utils/misc';

// fill it from env variables

const PENDING_AUTHOR_ID = 'PENDING_AUTHOR_ID';
const firebaseConfig = {
  wowowow: process.env.REACT_APP_ENDPOINT_URL,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const DB_NAME = process.env.REACT_APP_FB_DB_NAME; // 'BookNotesDev'; // TODO: Change this to BookNotesProd when ready to deploy
console.log('DB_NAME', DB_NAME);

export function initializeFirebase() {
  const app = initializeApp(firebaseConfig);
  return app;
}

export async function getTitleFromFirebase(bookNoteId) {
  const database = getDatabase();
  const bookNoteRef = ref(database, DB_NAME + '/' + bookNoteId + '/title');
  const bookNoteSnapshot = await get(bookNoteRef);
  if (bookNoteSnapshot.exists()) {
    const title = bookNoteSnapshot.val();
    return title;
  }
  return '';
}

export async function setTitleInFirebase(bookNoteId, title) {
  const database = getDatabase();
  const bookNoteRef = ref(database, DB_NAME + '/' + bookNoteId + '/title');
  await set(bookNoteRef, title);
}

export async function subscribeToTitleInFirebase(bookNoteId, setTitle) {
  const database = getDatabase();
  const bookNoteRef = ref(database, DB_NAME + '/' + bookNoteId + '/title');
  onValue(bookNoteRef, (snapshot) => {
    if (snapshot.exists()) {
      const title = snapshot.val();
      setTitle(title);
    }
  });
}

export function createInitialCellFromPrompt(prompt) {
  const cell = {
    type: 'textCell',
    sender: 'user',
    num: 0,
    data: {
      text: prompt,
      metadata: {},
    },
  };
  return cell;
}

export function writeBookNoteToFirebase(bookNoteId, cells, userId = 'bezzie') {
  const database = getDatabase();
  const cellsDict = convertListToOrderedDict(cells);
  const randomName = 'Untitled';
  set(ref(database, DB_NAME + '/' + bookNoteId), {
    cells: cellsDict,
    ownerId: userId,
    title: randomName,
    numVisits: -3,
  });
}

export async function readBookNoteFromFirebase(bookNoteId) {
  const database = getDatabase();
  const bookNoteRef = ref(database, DB_NAME + '/' + bookNoteId);
  const cells = [];
  // get cells ordered by their child 'num' get only 10
  const cellsQuery = query(bookNoteRef, orderByChild('num'));
  const cellsSnapshot = await get(cellsQuery);
  if (cellsSnapshot.exists()) {
    const cellsDict = cellsSnapshot.val();
    const cellsList = convertOrderedDictToList(cellsDict);
    return cellsList;
  } else {
    console.log('No data available');
  }

  return cells;
}

export async function getAllBookNotesFromFirebase() {
  const database = getDatabase();
  const bookNotesRef = ref(database, DB_NAME);
  // get cells ordered by their child 'numVisits'
  const bookNotesQuery = query(
    bookNotesRef,
    orderByChild('numVisits'),
    limitToLast(12)
  );
  const bookNotesSnapshot = await get(bookNotesQuery);
  if (bookNotesSnapshot.exists()) {
    const bookNotesDict = bookNotesSnapshot.val();
    return bookNotesDict;
  } else {
    console.log('No data available');
  }
  return [];
}

export function incrementNumVisits(bookNoteId) {
  const database = getDatabase();
  const bookNoteRef = ref(database, DB_NAME + '/' + bookNoteId);
  const updates = {};
  updates['/numVisits'] = increment(1);
  update(bookNoteRef, updates);
}

export function subscribeToBookNoteInFirebase(
  bookNoteId,
  setCells,
  authorId = 'bezzie'
) {
  const database = getDatabase();
  const bookNoteRef = ref(database, DB_NAME + '/' + bookNoteId);

  // check if bookNote exists
  onValue(bookNoteRef, (snapshot) => {
    if (snapshot.exists()) {
      if ('cells' in snapshot.val()) {
        const cellsDict = snapshot.val().cells;
        const cellsList = convertOrderedDictToList(cellsDict);
        setCells(cellsList);
      } else {
        const cellsList = [];
        setCells(cellsList);
      }
    } else {
      writeBookNoteToFirebase(bookNoteId, [], authorId);
    }
    //  else {

    //   writeBookNoteToFirebase(bookNoteId, []);
    // }
  });

  onValue(
    bookNoteRef,
    (snapshot) => {
      incrementNumVisits(bookNoteId);
    },
    {
      onlyOnce: true,
    }
  );
}

export function updateBookNoteInFirebase(bookNoteId, cells) {
  const database = getDatabase();
  const cellsDict = convertListToOrderedDict(cells);
  set(ref(database, DB_NAME + '/' + bookNoteId + '/cells'), cellsDict);
}

export function generateRandomHashUUID() {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let uuid = '';
  const length = 32;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    uuid += chars.charAt(randomIndex);
  }

  return uuid;
}

export let uploadFile = async (file) => {
  const storage = getStorage();
  const storageRef = sRef(
    storage,
    `files/${generateRandomHashUUID()}/` + file.name
  );
  await uploadBytes(storageRef, file);
  const url = await getDownloadURL(storageRef);
  return url;
};

export async function getAuthorFromFirebase(bookNoteId) {
  const database = getDatabase();
  const bookNoteRef = ref(database, DB_NAME + '/' + bookNoteId + '/ownerId');
  const bookNoteSnapshot = await get(bookNoteRef);
  if (bookNoteSnapshot.exists()) {
    const authorId = bookNoteSnapshot.val();
    return authorId;
  }
  return null;
}

export async function setAuthorInFirebase(bookNoteId, authorId) {
  // check if it has an author

  const database = getDatabase();
  const bookNoteRef = ref(database, DB_NAME + '/' + bookNoteId);
  // get current author
  onValue(bookNoteRef, (snapshot) => {
    if (snapshot.exists()) {
      // if ownerid is PENDING_AUTHOR_ID change it
      const updates = {};
      updates['/ownerId'] = authorId;
      update(bookNoteRef, updates);
    }
  });
}

export async function registerEmailOnWaitlist(email) {
  const database = getDatabase();
  const waitlistRef = ref(database, 'waitlist/');
  // push to waitlist
  await push(waitlistRef, email);
}

export async function getBookNotesByAuthor(authorId) {
  const database = getDatabase();
  const bookNotesRef = ref(database, DB_NAME);
  // get cells ordered by their child 'numVisits'
  const bookNotesQuery = query(
    bookNotesRef,
    orderByChild('ownerId'),
    equalTo(authorId)
  );
  const bookNotesSnapshot = await get(bookNotesQuery);
  if (bookNotesSnapshot.exists()) {
    const bookNotesDict = bookNotesSnapshot.val();
    return bookNotesDict;
  }
  return [];
}

export async function getUserProfilePhoto(userId) {
  const storage = getStorage();
  const storageRef = sRef(storage, `profilePhotos/${userId}`);
  const url = await getDownloadURL(storageRef);
  return url;
}

export async function uploadBookNotePreview(bookNoteId, previewBytes) {
  const storage = getStorage();
  const storageRef = sRef(storage, `previews/${bookNoteId}.png`);
  await uploadBytes(storageRef, previewBytes);
  const url = await getDownloadURL(storageRef);
  return url;
}

export async function getBookNotePreview(bookNoteId) {
  const storage = getStorage();
  const storageRef = sRef(storage, `previews/${bookNoteId}.png`);
  try {
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (err) {
    return null;
  }
}
