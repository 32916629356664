import { toBlob } from 'html-to-image';

import { uploadBookNotePreview } from '../Firebase';

const ENDPOINT_URL = process.env.REACT_APP_ENDPOINT_URL;

export const MALLEABLE_ID = 'malleable^&^';

export const askGPT = async (
  bookNoteId,
  prompt,
  updateCells,
  setIsStreaming,
  targetElement = null
) => {
  const input = prompt;

  const var_summaries = (await window.pythonRun(`summarize_globals()`)).result;

  const data = new FormData();
  data.append('filename', 'src/malleableDiv.js');
  data.append('prompt', input);
  data.append('var_summaries', JSON.stringify(var_summaries));
  data.append('booknote_id', JSON.stringify(bookNoteId));
  data.append('target_element', JSON.stringify(targetElement));

  console.log('var_summaries', var_summaries);

  try {
    const response = await fetch(`${ENDPOINT_URL}/stream_gen_app`, {
      method: 'POST',
      body: data,
    });

    if (!response.body) return;

    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .getReader();

    let firstChunk = true;
    setIsStreaming(true);
    while (true) {
      var { value, done } = await reader.read();
      console.log('value', value);
      if (done) {
        setIsStreaming(false);
        console.log('streaming done');
        console.log(
          'calling setTimeout(() => {takeAndUploadScreenshot(bookNoteId)}, 1500);'
        );
        setTimeout(() => {
          console.log('calling takeAndUploadScreenshot(bookNoteId)');
          takeAndUploadScreenshot(bookNoteId);
        }, 1500);
        break;
      }
      updateCells(JSON.parse(value), firstChunk);
      firstChunk = false;
    }
  } catch (err) {
    console.log('err', err.toString());
  }
};

export const getBookNotePromptIdea = async (past_queries = []) => {
  const data = new FormData();
  data.append('past_queries', JSON.stringify(past_queries));
  const response = await fetch(`${ENDPOINT_URL}/prompt_ideas`, {
    method: 'POST',
    body: data,
  });
  const json = await response.json();
  return json['idea'];
};

export const takeAndUploadScreenshot = async (bookNoteId) => {
  console.log('Taking and uploading screenshot');
  const node = document.getElementById(MALLEABLE_ID);
  const img = await toBlob(node);
  await uploadBookNotePreview(bookNoteId, img);
  console.log('Done uploading screenshot');
};
