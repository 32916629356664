import React, { useState } from 'react';
import { Spreadsheet } from 'react-spreadsheet';
import { JsonView } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import 'tailwindcss/tailwind.css';
import { jsx as _jsx } from 'react/jsx-runtime';
import { jsxs as _jsxs } from 'react/jsx-runtime';
import { ErrorBoundary } from 'react-error-boundary';
import { fallbackRender } from './OuputBox';

import PixelCanvas from './PixelCanvas';

import * as babel from '@babel/standalone';
import * as LucideIcons from 'lucide-react';
import * as BootStrapIcons from 'react-bootstrap-icons';

import { MALLEABLE_ID } from '../utils/askGPT';

function ContextBar({
  closeContextMenu,
  addCell,
  targetElement,
  bookNoteId,
  askGPT,
}) {
  const [prompt, setPrompt] = useState('');

  const handleInputChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      // Call your function here, e.g., handleSubmit()
      handleSubmit();
    }
  };

  const handleSubmit = (e) => {
    closeContextMenu();
    const updatedCells = addCell(prompt, {
      targetElement: targetElement.outerHTML,
    });
    // convert updatedCells to json
    const jsonConversation = JSON.stringify(updatedCells);
    // send to gpt
    console.log('Calling GPT', targetElement.outerHTML, bookNoteId);
    askGPT(bookNoteId, jsonConversation, targetElement.innerHTML).then(
      (code) => {
        console.log('Received code:', code);
        if (code === undefined) {
          console.error('Code is undefined, probably server is down');
          return;
        }
      }
    );
  };

  return (
    <>
      <div
        className='absolute top-0 left-0 transform -translate-x-2/4 -translate-y-1/2 bg-red-400 hover:bg-red-600 rounded-full h-5 w-5 flex items-center justify-center cursor-pointer'
        onClick={closeContextMenu}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
          className='h-4 w-4 text-white'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M6 18L18 6M6 6l12 12'
          />
        </svg>
      </div>
      <input
        className='rounded-l-lg w-full py-1 px-1 text-gray-700 leading-tight focus:outline-none'
        id='search'
        type='text'
        placeholder='Prompt to edit this component...'
        onChange={handleInputChange}
        onKeyDown={handleEnterPress}
        onClick={(e) => e.stopPropagation()}
      />
      <div
        className='p-1 m-1 rounded-md bg-red-400 hover:bg-red-600 text-white cursor-pointer'
        onClick={() => handleSubmit()}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
          className='h-5 w-5'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M14 5l7 7m0 0l-7 7m7-7H3'
          />
        </svg>
      </div>
    </>
  );
}

export function MalleableDiv({ reactCode, addCell, bookNoteId, askGPT }) {
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    targetElement: null,
  });
  const handleContextMenu = (event) => {
    event.preventDefault();
    const targetElement = event.target;

    console.log(targetElement);
    setContextMenu({
      targetElement: targetElement,
      visible: true,
      x: event.clientX,
      y: event.clientY,
    });
  };

  const closeContextMenu = (event) => {
    setContextMenu({ visible: false, x: 0, y: 0 });
    return false;
  };

  console.log('reactCode', reactCode);
  let UserDiv;
  if (reactCode === '') {
    UserDiv = () => <div></div>;
  } else {
    const babelCode = babel.transform(reactCode, { presets: ['react'] }).code;
    const code = babelCode.replace('"use strict";', '').trim();
    const func = new Function(
      'React',
      'Spreadsheet',
      'JsonView',
      'PixelCanvas',
      `LucideIcons`,
      `BootStrapIcons`,
      `return ${code}`
    );
    UserDiv = func(
      React,
      Spreadsheet,
      JsonView,
      PixelCanvas,
      LucideIcons,
      BootStrapIcons
    );
  }
  return (
    <div
      id={MALLEABLE_ID}
      onContextMenu={handleContextMenu}
      className='flex flex-col overflow-scroll'
    >
      <ErrorBoundary fallbackRender={fallbackRender}>
        <UserDiv />
        {contextMenu.visible && (
          <div
            style={{ top: contextMenu.y, left: contextMenu.x }}
            className='absolute bg-white rounded-md shadow-md flex items-center space-x-4 p-1 contextMenu w-96'
          >
            <ContextBar
              onClick={(e) => e.stopPropagation()}
              closeContextMenu={closeContextMenu}
              bookNoteId={bookNoteId}
              addCell={addCell}
              askGPT={askGPT}
              targetElement={contextMenu.targetElement}
            />
          </div>
        )}
      </ErrorBoundary>
    </div>
  );
}
