import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { generateBookNoteHash } from '../utils/misc';

const NewEditorRedirect = () => {
  const navigate = useNavigate();
  const { firstPrompt } = useParams();
  console.log('firstPrompt', firstPrompt);

  useEffect(() => {
    // Generate your random_editor_id here (e.g., using a library or function)
    const random_editor_id = generateBookNoteHash(); // Replace with your random ID generation logic

    // Redirect to the editor with the generated random_editor_id
    navigate(`/new/${random_editor_id}/${firstPrompt}`);
  }, [navigate]);

  return null; // You can return null as we are handling the redirect in useEffect
};

export default NewEditorRedirect;
