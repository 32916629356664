// Router.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import EditorPage from './pages/Editor';
import { LostPage } from './NotFound';
import { initializeFirebase } from './Firebase';
import NewEditorRedirect from './pages/NewEditor';
import Dashboard from './pages/Dashboard';
import { GoogleAuthProvider } from 'firebase/auth';
import { AuthPage } from './pages/AuthPage';
import { AuthPageGPT } from './pages/AuthPageGPT';
import LandingPage from './pages/LandingPage';
import { AppPage } from './pages/Editor';

import { onAuthStateChanged, getAuth } from 'firebase/auth';
import SandBoxPage from './pages/SandBox';
const REDIRECT_TO = '/';
initializeFirebase();

const auth = getAuth();
const RequireAuth = ({ children, redirectTo }) => {
  const [user, setUser] = React.useState(null);
  React.useEffect(() => {
    // if the user is not logged in navigate to login page. If they are logged in return the children
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        window.location.href = redirectTo;
      }
    });
  }, [user]);
  if (user) {
    return children;
  } else {
    return null;
  }
};

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/editor/:bookNoteId/' element={<EditorPage />} />
        <Route path='/:bookNoteId' element={<AppPage />} />

        <Route path='/new' element={<NewEditorRedirect />} />
        <Route path='/' element={<Dashboard />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route
          path='/new/:bookNoteId/'
          element={
            <RequireAuth redirectTo={REDIRECT_TO}>
              <EditorPage />
            </RequireAuth>
          }
        />
        <Route path='/login' element={<AuthPage />} />

        <Route
          path='/editor/:bookNoteId/newFromGPT'
          element={
            <RequireAuth redirectTo='/readylogin'>
              <EditorPage />
            </RequireAuth>
          }
        />

        <Route path='/editor/:bookNoteId/run/' element={<EditorPage />} />
        <Route
          path='/new/:bookNoteId/:firstPrompt'
          element={
            <RequireAuth redirectTo={REDIRECT_TO}>
              <EditorPage />
            </RequireAuth>
          }
        />
        <Route path='/readylogin' element={<AuthPageGPT />} />
        {/* <Route path='/home' element={<LandingPage />} /> */}
        {/* <Route path='/sandbox' element={<SandBoxPage />} /> */}
        <Route path='*' element={<LostPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
