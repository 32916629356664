// make it extensible and test it
// remove python
// think about repo integration
/* eslint-disable jsx-a11y/anchor-is-valid */

// Notes:
// All states are managed through firebase, cells get updated in firebase then the booknote updates based on that.

import React, { useState, useEffect, useRef } from 'react';
import { TopBar } from '../components/TopBar';

import { LoadingPage } from './LoadingPage';
import Cell from '../components/Cell';
import { SplitPane } from '../components/SplitPane';

import './Editor.css';
import PixelCanvas from '../components/PixelCanvas';

import { loadFilePyodide } from '../utils/fileUpload';
import { askGPT } from '../utils/askGPT';
import {
  updateBookNoteInFirebase,
  subscribeToBookNoteInFirebase,
  getTitleFromFirebase,
  setAuthorInFirebase,
  getAuthorFromFirebase,
} from '../Firebase';

import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { GoogleAuthProvider, signInAnonymously } from 'firebase/auth';

import { useParams } from 'react-router-dom';
import InputBox from '../components/InputBox';
import OutputBox from '../components/OuputBox';

const worker = new Worker(`${process.env.PUBLIC_URL}/pyodideWorker.js`);

const callbacks = {};
const PENDING_AUTHOR_ID = 'PENDING_AUTHOR_ID';

const pythonRun = (() => {
  let id = 0; // identify a Promise
  return (python) => {
    // the id could be generated more carefully
    id = (id + 1) % Number.MAX_SAFE_INTEGER;
    return new Promise((onSuccess) => {
      callbacks[id] = onSuccess;
      if (id == 1) {
        worker.onmessage = (event) => {
          if (event.data.type === 'stdout') {
            console.log('stdout', event.data.stdout);
            return;
          }
          if (event.data.type === 'canvasOut') {
            return;
          }
          let { id, result, stdout, canvasOuts } = event.data;
          let onSuccess = callbacks[id];
          console.log('onSuccess');
          delete callbacks[id];
          console.log('data', result);
          console.log('stdout', stdout);
          console.log('worker', worker);
          console.log('canvasOuts', canvasOuts);
          console.log('success call', { result, stdout, canvasOuts });
          onSuccess({ result, stdout, canvasOuts });
        };
      }
      worker.postMessage({
        python,
        id,
      });
    });
  };
})();
window.pythonRun = pythonRun;

async function runAllCells(cells, runCode) {
  console.log('Starting runAllCells');
  for (let i = 0; i < cells.length; i++) {
    if (cells[i].type === 'codeCell') {
      await runCode(i, cells[i].data.text);
    } else {
    }
  }
}

function EditorPage(props) {
  console.log('Editor');

  const { bookNoteId } = useParams();
  const isNew = window.location.pathname.includes('/new/');
  // check if previous url is from https://chat.openai.com/
  // const fromCHATGPT = document.referrer.includes('chat.openai.com');
  const isfromChatGPT = window.location.pathname.includes('/newFromGPT');

  const toRun = window.location.pathname.includes('/run');
  const [isBookNoteCreated, setIsBookNoteCreated] = useState(!isNew);
  const [currIndex, setCurrIndex] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [running, setRunning] = useState({});
  const [title, setTitle] = useState(null);
  const [authorId, setAuthorId] = useState('');
  const [cells, setcells] = useState([]);
  const [user, setUser] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const [isStreaming, setIsStreaming] = useState(false);
  const [outputBoxCells, setOutputBoxCells] = useState([]);

  const runningRef = useRef();
  runningRef.current = running;
  const cellsRef = useRef();
  cellsRef.current = cells;
  const cellScrollDiv = useRef(null);

  const authorIdRef = useRef();
  authorIdRef.current = authorId;

  const userRef = useRef();
  userRef.current = user;

  const toRunRef = useRef();
  toRunRef.current = toRun;

  const isBookNoteCreatedRef = useRef();
  isBookNoteCreatedRef.current = isBookNoteCreated;

  const auth = getAuth();

  function setCellsState(updatedCells) {
    // setcells(updatedCells); (handle everything on firebase onupdate)
    updateBookNoteInFirebase(bookNoteId, updatedCells);
  }

  useEffect(() => {
    if (cells.length === 0) {
      return;
    }
    if (currIndex === cells.length - 1) {
      setTimeout(() => {
        cellScrollDiv.current.scrollTop = cellScrollDiv.current.scrollHeight;
      }, 50);
    }
  }, [cells, currIndex]);

  // read booknote from firebase
  useEffect(() => {
    if (isNew) {
      // there is nothing to load
      setIsLoaded(true);
      if (userRef.current === null) {
        setAuthorId(PENDING_AUTHOR_ID);
      }
      // change url to editor/:booknoteid
      window.history.replaceState(
        null,
        null,
        window.history.replaceState(null, null, `/editor/${bookNoteId}`)
      );
    }

    getTitleFromFirebase(bookNoteId).then((title) => {
      console.log('title', title);
      setTitle(title);
      // set document title
      document.title = title;
    });

    console.log('authorIdRef.current', authorIdRef.current);

    subscribeToBookNoteInFirebase(
      bookNoteId,
      (updatedCells) => {
        setIsBookNoteCreated(true);
        console.log('updatedCells', updatedCells);
        if (updatedCells) {
          if (
            JSON.stringify(cellsRef.current) === JSON.stringify(updatedCells)
          ) {
            setIsLoaded(true);
            return;
          } else {
            // Deal with firebase firing twice onload
            if (cellsRef.current.length === 0) {
              if (Object.values(runningRef.current).some(Boolean)) {
                console.log('NOT FIRING');
                return;
              }
            }
            if (cellsRef.current.length >= 2) {
              setCurrIndex(updatedCells.length - 1);
            }
            // run all cells in updated cells and then set isLoaded to true
            runAllCells(updatedCells, runCode).then(() => {
              if (!isNew) {
                setIsLoaded(true);
              }

              if (cellsRef.current.length === 0) {
                setcells(updatedCells);
                setCurrIndex(updatedCells.length - 1);
                setOutputBoxCells(updatedCells);
              } else {
                setcells(updatedCells);
                setOutputBoxCells(updatedCells);
              }
            });
            if (updatedCells.length === 1 && toRunRef.current) {
              toRunRef.current = false;
              streamGPT(bookNoteId, JSON.stringify(updatedCells));
              window.history.replaceState(
                null,
                null,
                window.history.replaceState(null, null, `/editor/${bookNoteId}`)
              );
            }
          }
        }
      },
      authorIdRef.current
    );
  }, [bookNoteId, isNew, cellsRef]);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
      if (isNew) {
        setAuthorId(user.uid);
        setAuthorInFirebase(bookNoteId, user.uid);
      }

      getAuthorFromFirebase(bookNoteId).then((firebaseAuthor) => {
        console.log('firebaseAuthor', firebaseAuthor);
        if (firebaseAuthor === user.uid) {
          setReadOnly(false);
        } else {
          console.log('isfromChatGPT', isfromChatGPT);
          if (isfromChatGPT) {
            if (firebaseAuthor === 'gpt') {
              console.log('setAuthorId', firebaseAuthor, user.uid);
              setAuthorId(user.uid);
              setAuthorInFirebase(bookNoteId, user.uid);
              setReadOnly(false);
            } else {
              setReadOnly(true);
            }
          }
        }
      });
    } else {
      // User is signed out
      setUser(null);
    }
  });

  const streamGPT = async (bookNoteId, prompt, targetElement = null) => {
    let updateCells = (newCellData, firstChunk) => {
      // if (!firstChunk) {
      //   deleteCell(cells.length - 1);
      // }
      const newCell = {
        type: 'codeCell',
        sender: 'AI',
        num: cells.length,
        data: {
          text: newCellData.py_code,
          jsCode: newCellData.js_code,
          explanationText: newCellData.explanation_text,
          pyStdOut: '',
          pyReturn: '',
        },
      };
      if (firstChunk) {
        setcells((prevCells) => [...prevCells, newCell]);
        setCurrIndex(cells.length + 1);
        setOutputBoxCells((prevCells) => [...prevCells, newCell]);
      } else {
        setcells((prevCells) => [...prevCells.slice(0, -1), newCell]);
        setOutputBoxCells((prevCells) => [...prevCells.slice(0, -1), newCell]);
      }
    };
    await askGPT(
      bookNoteId,
      prompt,
      updateCells,
      setIsStreaming,
      targetElement
    );
  };

  const runCode = async (index, python) => {
    let newRunning = { ...running };
    if (runningRef.current[index]) {
      return;
    }
    console.log('runCode', index, python);
    newRunning[index] = true;
    setRunning(newRunning);
    const { result, stdout, canvasOuts } = await pythonRun(python, index);
    console.log('Ran python', index, python, canvasOuts);
    let newNewRunning = { ...newRunning };
    newNewRunning[index] = false;
    setRunning(newNewRunning);
  };

  const addCell = (text, cells, sender = 'user', metadata = {}) => {
    const updatedCells = [
      ...cells,
      {
        type: 'textCell',
        sender: sender,
        num: cells.length,
        data: {
          text: text,
          metadata: metadata,
        },
      },
    ];
    setCellsState(updatedCells);
    setCurrIndex(cells.length + 1);

    return updatedCells;
  };

  const addCodeCell = (PyCode, JsCode, ExplanationText, cells) => {
    const newCell = {
      type: 'codeCell',
      sender: 'AI',
      num: cells.length,
      data: {
        text: PyCode,
        jsCode: JsCode,
        explanationText: ExplanationText,
        pyStdOut: '',
        pyReturn: '',
      },
    };
    const updatedCells = [...cells, newCell];
    setCellsState(updatedCells);
    setCurrIndex(cells.length + 1);
    //runCode(cells.length, PyCode);
  };

  const loadFile = async (file) => {
    const [pyCode, jsCode] = await loadFilePyodide(file, worker);
    const updatedCells = addCell(`Upload ${file.name}`, cells);
    addCodeCell(pyCode, jsCode, '', updatedCells);
  };

  const deleteCell = (index) => {
    const updatedcells = [...cells];
    updatedcells.splice(index, 1);
    setCellsState(updatedcells);
  };

  const handlePyCodeChange = (index, code) => {
    console.log('handlePyCodeChange', index, code);
    const updatedcells = [...cells];
    if (updatedcells[index].type === 'codeCell') {
      updatedcells[index].data.text = code;
      runCode(index, code);
    }
    setCellsState(updatedcells);
  };

  const handleJsCodeChange = (index, code) => {
    console.log('handleJsCodeChange', index, code);
    const updatedcells = [...cells];
    if (updatedcells[index].type === 'codeCell') {
      updatedcells[index].data.jsCode = code;
    }
    setCellsState(updatedcells);
    setOutputBoxCells(updatedcells);
  };

  const handleKeyDown = (e, index) => {
    // delete when cmd + delete is pressed and text in the input box is empty
    if ((e.metaKey || e.ctrlKey) && e.key === 'Backspace' && !e.target.value) {
      console.log('delete');
      e.preventDefault();
      deleteCell(cells.length - 1);
    }

    // shift + enter shouldn't submit the form
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
    }
  };

  // updateBookNoteInFirebase(bookNoteId, cells);
  if (!isLoaded) {
    return <LoadingPage />;
  }
  return (
    <div className='h-screen w-screen flex-col flex'>
      <div className=' shadow-sm z-10 h-12  w-full  bg-white items-center flex flex-row px-0 pb-1 '>
        <TopBar bookNoteId={bookNoteId} showAppButton />
      </div>

      <SplitPane className='h-[calc(100%-3rem)] flex-grow  flex-row flex   '>
        <div
          id='chat-window'
          className='px-1 py-2 w-full flex flex-col    h-full   shadow-inner-lg '
        >
          <div
            id='cell-scroll'
            ref={cellScrollDiv}
            className='flex flex-col pb-1 h-full space-y-1 overflow-y-scroll '
          >
            {cells.map((cell, index) => (
              <Cell
                name={`cell-${index}`}
                key={`cell-${index}`}
                cell={cell}
                index={index}
                currIndex={currIndex}
                setCurrIndex={setCurrIndex}
                cells={cells}
                setcells={setCellsState}
                handlePyCodeChange={handlePyCodeChange}
                handleJsCodeChange={handleJsCodeChange}
                readOnly={readOnly}
                userProfileURL={user ? user.photoURL : null}
                isStreaming={isStreaming}
              />
            ))}
          </div>
          {!readOnly ? (
            <div className='mt-0 '>
              <InputBox
                cells={cells}
                addCell={addCell}
                currIndex={currIndex}
                addCodeCell={addCodeCell}
                askGPT={streamGPT}
                bookNoteId={bookNoteId}
                loadFile={loadFile}
                setCurrIndex={setCurrIndex}
                handleKeyDown={handleKeyDown}
                userProfileURL={user ? user.photoURL : null}
              />
            </div>
          ) : (
            <div className='mt-0 '>
              <div
                className={`flex bg-white text-gray-400 px-2 py-1 flex-row w-full rounded-lg ${
                  currIndex === cells.length
                    ? 'border border-gray-400 shadow-lg '
                    : 'border border-gray-200'
                }`}
              >
                This booknote is readonly
              </div>
            </div>
          )}
        </div>
        <>
          {outputBoxCells.length > 0 &&
            (currIndex < outputBoxCells.length ? (
              <OutputBox
                cell={outputBoxCells[currIndex]}
                addCell={(text, metadata) =>
                  addCell(text, cells, 'user', metadata)
                }
                askGPT={streamGPT}
                bookNoteId={bookNoteId}
                isStreaming={isStreaming}
              />
            ) : (
              <OutputBox
                cell={outputBoxCells[outputBoxCells.length - 1]}
                addCell={(text, metadata) =>
                  addCell(text, cells, 'user', metadata)
                }
                askGPT={streamGPT}
                bookNoteId={bookNoteId}
                isStreaming={isStreaming}
              />
            ))}

          {cells.length === 0 && (
            <div className='flex flex-col items-center justify-center w-full h-full'>
              <div className='border-4 border-gray-200 text-center px-2 py-3 rounded-lg text-gray-300 font-semibold text-3xl '>
                Your creations will be here
              </div>
            </div>
          )}
        </>
      </SplitPane>
    </div>
  );
}

function AppPage(props) {
  console.log('Editor');

  const { bookNoteId, firstPrompt } = useParams();
  const isNew = window.location.pathname.includes('/new/');
  console.log('firstPrompt', firstPrompt);

  const [isBookNoteCreated, setIsBookNoteCreated] = useState(!isNew);
  const [currIndex, setCurrIndex] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [running, setRunning] = useState({});
  const [title, setTitle] = useState(null);
  const [authorId, setAuthorId] = useState('');
  const [cells, setcells] = useState([]);
  const [user, setUser] = useState(null);

  const runningRef = useRef();
  runningRef.current = running;
  const cellsRef = useRef();
  cellsRef.current = cells;
  const cellScrollDiv = useRef(null);

  const authorIdRef = useRef();
  authorIdRef.current = authorId;

  const userRef = useRef();
  userRef.current = user;

  const isBookNoteCreatedRef = useRef();
  isBookNoteCreatedRef.current = isBookNoteCreated;

  const auth = getAuth();

  function setCellsState(updatedCells) {
    // setcells(updatedCells); (handle everything on firebase onupdate)
    updateBookNoteInFirebase(bookNoteId, updatedCells);
  }

  // read booknote from firebase
  useEffect(() => {
    // if (isNew) {
    //   // there is nothing to load
    //   setIsLoaded(true);
    //   if (userRef.current === null) {
    //     setAuthorId(PENDING_AUTHOR_ID);
    //   }
    //   // change url to editor/:booknoteid
    //   // window.history.replaceState(null, null, `/editor/${bookNoteId}`);
    // }

    getTitleFromFirebase(bookNoteId).then((title) => {
      console.log('title', title);
      setTitle(title);
      document.title = title;
    });

    console.log('authorIdRef.current', authorIdRef.current);

    subscribeToBookNoteInFirebase(
      bookNoteId,
      (updatedCells) => {
        setIsBookNoteCreated(true);
        console.log('updatedCells', updatedCells);
        if (updatedCells) {
          if (
            JSON.stringify(cellsRef.current) === JSON.stringify(updatedCells)
          ) {
            setIsLoaded(true);
            return;
          } else {
            // Deal with firebase firing twice onload
            if (cellsRef.current.length === 0) {
              if (Object.values(runningRef.current).some(Boolean)) {
                console.log('NOT FIRING');
                return;
              }
            }
            if (cellsRef.current.length >= 2) {
              setCurrIndex(updatedCells.length - 1);
            }
            // run all cells in updated cells and then set isLoaded to true
            runAllCells(updatedCells, runCode).then(() => {
              if (!isNew) {
                setIsLoaded(true);
              }
              if (cellsRef.current.length === 0) {
                setcells(updatedCells);
                setCurrIndex(updatedCells.length - 1);
              } else {
                setcells(updatedCells);
              }
            });
          }
        }
      },
      authorIdRef.current
    );
  }, [bookNoteId, isNew, cellsRef]);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
      if (isNew) {
        setAuthorId(user.uid);
        setAuthorInFirebase(bookNoteId, user.uid);
      }
    } else {
      // User is signed out
      setUser(null);
    }
  });

  const runCode = async (index, python) => {
    let newRunning = { ...running };
    if (runningRef.current[index]) {
      return;
    }
    console.log('runCode', index, python);
    newRunning[index] = true;
    setRunning(newRunning);
    const { result, stdout, canvasOuts } = await pythonRun(python, index);
    console.log('Ran python', index, python, canvasOuts);
    let newNewRunning = { ...newRunning };
    newNewRunning[index] = false;
    setRunning(newNewRunning);
  };

  // updateBookNoteInFirebase(bookNoteId, cells);
  if (!isLoaded) {
    return <LoadingPage />;
  }

  return (
    <div className='h-screen w-screen flex-col flex'>
      {cells.length > 0 &&
        (currIndex < cells.length ? (
          <OutputBox noFrame={true} cell={cells[currIndex]} />
        ) : (
          <OutputBox noFrame={true} cell={cells[cells.length - 1]} />
        ))}

      {cells.length === 0 && (
        <div className='flex flex-col items-center justify-center w-full h-full'></div>
      )}
    </div>
  );
}

export default EditorPage;
export { AppPage };
