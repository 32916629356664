import {
  SPREADSHEET_DIV,
  JSON_DIV,
  PRINT_DIV,
  PRINT_VAR_DIV,
} from './FileViews.js';
import { uploadFile } from '../Firebase';

const FILES = [];

const Pandas_to_Spreadsheet = `
def pandas_to_list(df):
  output_data = []
  output_data.append([{ "value": col} for col in df.columns])
  for _, row in df.iterrows():
      output_data.append([{ "value": value } for value in row])
  return output_data
  `;

export function createObjectURL(file) {
  if (window.webkitURL) {
    return window.webkitURL.createObjectURL(file);
  } else if (window.URL && window.URL.createObjectURL) {
    return window.URL.createObjectURL(file);
  } else {
    return null;
  }
}

function fileNameToVariableName(fileName) {
  // Remove spaces and special characters (except underscore and dollar sign)
  const cleanFileName = fileName.replace(/[^\w$]/g, '');

  // Check if the first character is a digit, and if so, prefix with an underscore
  let variableName = cleanFileName;
  if (/^\d/.test(variableName)) {
    variableName = '_' + variableName;
  }

  // Make sure the variable name starts with a letter, underscore, or dollar sign
  if (!/^[a-zA-Z_$]/.test(variableName)) {
    variableName = '_' + variableName;
  }

  return variableName;
}

export let loadFilePyodide = async (file, worker) => {
  let file_url = await uploadFile(file); // createObjectURL(file)
  let fname = fileNameToVariableName(
    file.name.split('.').slice(0, -1).join('.')
  );
  const fileExtension = file.name.split('.').pop();
  let file_prefix = `${fname}_${fileExtension}`;
  let uploadPythonScript = `${file_prefix}_buffer = io.BytesIO(await get_bytes("${file_url}"))`;
  let jsCode = '';
  if (fileExtension === 'csv') {
    uploadPythonScript += `\n${fname}_df = pd.read_csv(${file_prefix}_buffer)\n${Pandas_to_Spreadsheet}`;
    jsCode = SPREADSHEET_DIV(`${fname}_df`);
  } else if (fileExtension === 'xlsx') {
    uploadPythonScript += `\n${fname}_df = pd.read_excel(${file_prefix}_buffer)\n${Pandas_to_Spreadsheet}`;
    jsCode = SPREADSHEET_DIV(`${fname}_df`);
  } else if (fileExtension === 'json') {
    uploadPythonScript += `\n${fname}_json = json.loads(${file_prefix}_buffer.getvalue().decode())`;
    jsCode = JSON_DIV(`${fname}_json`);
  } else if (fileExtension === 'pkl' || fileExtension === 'pickle') {
    uploadPythonScript += `\n${fname} = pickle.loads(${file_prefix}_buffer.getvalue())`;
    jsCode = PRINT_DIV(`"Uploaded ${file.name}"`);
  } else if (fileExtension === 'zip') {
    uploadPythonScript += `\n${fname}_zip = zipfile.ZipFile(${file_prefix}_buffer, 'r')`;
    jsCode = PRINT_VAR_DIV(`${fname}_zip.printdir()`);
  } else {
    uploadPythonScript += `\n"Uploaded ${file.name}"`;
    jsCode = PRINT_DIV(`"Uploaded ${file.name}"`);
  }
  await window.pythonRun(uploadPythonScript);
  return [uploadPythonScript, jsCode];
};
