import React from 'react';
import { twMerge } from 'tailwind-merge';

export function SplitPane({ children, className = '' }) {
  console.log('SplitPane', className);
  const [splitPaneSize, setSplitPaneSize] = React.useState(33);
  const containerRef = React.useRef(null);
  const handleMouseMove = (e) => {
    e.preventDefault();
    const containerRect = containerRef.current.getBoundingClientRect();
    const newSplitPaneSize =
      ((e.clientX - containerRect.left) / containerRect.width) * 100;
    setSplitPaneSize(newSplitPaneSize);
  };
  const handleMouseUp = () => {
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  };
  const handleMouseDown = (e) => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };
  return (
    <div
      ref={containerRef}
      className={twMerge('flex w-full h-[calc(100%)]', className)}
    >
      <div className='h-full  ' style={{ width: `${splitPaneSize}%` }}>
        {children[0]}
      </div>
      <div
        className='cursor-col-resize my-1.5 flex-row rounded-full py-1 flex justify-center bg-gray-50 w-2 hover:bg-gray-200 '
        onMouseDown={handleMouseDown}
      >
        <div className='h-full bg-gray-300' style={{ width: '1px' }}></div>
      </div>
      <div className='h-full px-1' style={{ width: `${100 - splitPaneSize}%` }}>
        {children[1]}
      </div>
    </div>
  );
}
