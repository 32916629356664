import React from 'react';
import {
  GoogleAuthProvider,
  getAuth,
  linkWithCredential,
  onAuthStateChanged,
  signInWithPopup,
} from 'firebase/auth';

export function AuthPageGPT(props) {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // check if user is anonymous
      if (user.isAnonymous) {
        return;
      }

      window.location.href = '/dashboard';
    } else {
      // navigate to auth page
    }
  });

  return (
    <div className='h-screen w-screen flex flex-col justify-center bg-gradient-to-r from-gray-900 to-black'>
      <div
        className=' absolute top-0 left-0 ml-2 mt-1'
        onClick={() => {
          window.location.href = '/dashboard';
        }}
      >
        <span className='text-4xl cursor-pointer select-none mt-1 text-gray-400 '>
          Book
        </span>
        <span className='text-4xl cursor-pointer select-none mt-1 text-red-500 '>
          Note
        </span>
      </div>

      <div className='flex flex-col self-center    rounded-lg px-2  py-2 items-center justify-center'>
        <div className='flex flex-col bg-gray-700 pb-5 pt-3 px-5 rounded-lg bg-opacity-10 border border-gray-800'>
          <span className='text-green-500 text-center text-3xl mb-4 font-semibold'>
            Your BookNote is Ready
          </span>
          <button
            className=' hover:bg-gray-800 bg-gray-900 border-gray-800 border rounded-lg pb-3 text-3xl focus:outline-none cursor-pointer text-gray-500 font-light py-2 px-4 '
            onClick={() => {
              const provider = new GoogleAuthProvider();
              const auth = getAuth();
              signInWithPopup(auth, provider)
                .then((result) => {
                  // This gives you a Google Access Token. You can use it to access the Google API.
                  const credential =
                    GoogleAuthProvider.credentialFromResult(result);
                  const token = credential.accessToken;
                  // The signed-in user info.
                  const user = result.user;
                  // link with anonymous account
                  linkWithCredential(auth.currentUser, credential)
                    .then((usercred) => {
                      console.log(
                        'Anonymous account successfully upgraded',
                        usercred.user
                      );
                      // navigate to dashboard
                      window.location.href = '/dashboard';
                    })
                    .catch((error) => {
                      console.log('Error upgrading anonymous account', error);
                    });
                })
                .catch((error) => {
                  // Handle Errors here.
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  // The email of the user's account used.
                  const email = error.email;
                  // The AuthCredential type that was used.
                  const credential =
                    GoogleAuthProvider.credentialFromError(error);
                  // ...
                });
            }}
          >
            <span className='mr-2'>Sign in with</span>
            <span className='text-blue-500'>G</span>
            <span className='text-red-500 '>o</span>
            <span className='text-yellow-500'>o</span>
            <span className='text-blue-500'>g</span>
            <span className='text-green-500'>l</span>
            <span className='text-red-500'>e</span>
          </button>
        </div>
      </div>
    </div>
  );
}
