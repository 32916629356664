import { useEffect, useState } from 'react';
import { getBookNotePreview } from '../Firebase';
import { Link, useNavigate } from 'react-router-dom';

export function BNCard({ bookNoteId, bookNoteTitle }) {
  const navigate = useNavigate();

  const [templateImage, setTemplateImage] = useState('');

  const cutTitle = (title) => {
    if (title == null) return 'Untitled';
    if (title.length > 35) {
      return title.substring(0, 35).trim() + '...';
    }
    return title;
  };

  useEffect(() => {
    getBookNotePreview(bookNoteId).then((url) => {
      setTemplateImage(url || '');
    });
  }, []);

  return (
    <Link
      to={`/editor/${bookNoteId}`}
      className='min-w-full duration-100 hover:cursor-pointer  mx-auto bg-white   overflow-hidden shadow shadow-gray-500  '
    >
      {templateImage === '' ? (
        // <div className='w-full h-64 py-2 px-2 object-cover  hover:bg-gray-200 flex flex-row justify-center items-center'>
        //   <span className='text-red-700 font-bold'>{bookNoteTitle}</span>
        // </div>

        <div className=' bottom-0 w-full h-64  justify-center flex flex-col text-center backdrop-blur-xs bg-gray-900 bg-opacity-50   hover:bg-opacity-30 z-10  '>
          <div className='font-semibold  w-full px-2  text-2xl py-3 text-gray-100 '>
            {cutTitle(bookNoteTitle)}
          </div>
        </div>
      ) : (
        <div className='relative z-0 '>
          <img
            className='w-full h-64  z-0 object-cover	  '
            src={templateImage}
            alt={bookNoteTitle}
          />
          <div className='absolute  bottom-0 w-full h-full justify-center flex flex-col text-center backdrop-blur-xs hover:backdrop-blur-md bg-gray-700 bg-opacity-75 text-gray-100  hover:text-white hover:bg-opacity-50 z-10  '>
            <div className='font-semibold  w-full px-2  text-2xl py-3 '>
              {cutTitle(bookNoteTitle)}
            </div>
          </div>
        </div>
      )}
    </Link>
  );
}
