export const SPREADSHEET_DIV = (var_name) => `
function UserDiv () {
    const [data, setData] = React.useState([]);
    React.useEffect(() => {
        window.pythonRun("pandas_to_list(${var_name})").then((resp) => {
            setData(resp.result)
        })
    }, []);
    return (
        <div>
            <Spreadsheet data={data}/>
        </div>
    )
}
`;

export const JSON_DIV = (var_name) => `
function UserDiv () {
    const [data, setData] = React.useState({});
    React.useEffect(() => {
        window.pythonRun("json.dumps(${var_name})").then((resp) => {
            setData(JSON.parse(resp.result))
        })
    }, []);
    return (
        <div>
            <JsonView data={data}/>
        </div>
    )
}
`;

export const PRINT_DIV = (print_str) => `
function UserDiv () {
    return (
        <div>
            <pre>${print_str}</pre>
        </div>
    )
}
`;

export const PRINT_VAR_DIV = (print_str) => `
function UserDiv () {
    const [data, setData] = React.useState([]);
    React.useEffect(() => {
        window.pythonRun("${print_str}").then((resp) => {
            setData(resp.stdout)
        })
    }, []);
    return (
        <div>
            <pre>{data}</pre>
        </div>
    )
}
`;
