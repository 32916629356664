import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { UserLogo, UploadIcon, SendIcon, ZapIcon } from './SVGs';

import { getBookNotePromptIdea } from '../utils/askGPT';
import { twMerge } from 'tailwind-merge';

export default function InputBox(props) {
  const {
    cells,
    addCell,
    currIndex,
    bookNoteId,
    askGPT,
    onCreate,
    onDrop,
    loadFile,
    setCurrIndex,
    handleKeyDown,
    userProfileURL,
    className,
  } = props;
  const [loading, setLoading] = useState(false);
  const [command, setCommand] = useState('');
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  const addIdea = async () => {
    setLoading(true);
    const idea = await getBookNotePromptIdea(
      cells
        .filter((cell) => cell.type === 'textCell')
        .map((cell) => cell.data.text || '')
    );
    setCommand(idea);
    setLoading(false);
  };

  const submitToGPT = (e) => {
    e.preventDefault();
    if (e.target[0].value === '') {
      return;
    }
    const text = e.target[0].value;
    e.target[0].value = '';
    setCommand('');
    const updatedCells = addCell(text, cells);
    // convert updatedCells to json
    const jsonConversation = JSON.stringify(updatedCells);
    // send to gpt
    console.log('Calling GPT');
    askGPT(bookNoteId, jsonConversation, (progress) => {
      console.log('Received data:', progress);
    }).then((code) => {
      console.log('Received code:', code);
      if (code === undefined) {
        console.error('Code is undefined, probably server is down');
        return;
      }
    });
  };

  return (
    <div
      {...getRootProps()}
      className={twMerge(
        `flex bg-white flex-row w-full  rounded-lg ${
          currIndex === cells.length && !isDragActive
            ? 'border border-gray-400 shadow-lg '
            : 'border border-gray-200'
        } ${isDragActive ? 'border border-red-400' : ' '} `,
        className
      )}
    >
      <div
        className={`w-12 h-12  items-center flex justify-center p-1   select-none`}
      >
        {userProfileURL ? (
          <img
            className='h-9 w-9 rounded-full object-cover ml-0.5 select-none'
            src={userProfileURL}
            alt='user'
          />
        ) : (
          <UserLogo />
        )}
      </div>
      <form
        className={` w-full  h-12 flex-row flex space-x-1 justify-center`}
        onSubmit={(e) => {
          if (!onCreate) {
            submitToGPT(e);
          } else {
            e.preventDefault();
            onCreate(e.target[0].value);
          }
        }}
      >
        {!loading ? (
          <input
            {...getRootProps()}
            onDrop={(e) => {
              loadFile(e.dataTransfer.files[0]);
            }}
            type='text'
            className={`px-3 ml-1   w-full h-12 whitespace-pre-wrap  focus:outline-none bg-transparent ${
              isDragActive ? ' rounded-lg  font-bold placeholder-red-400' : ''
            } `}
            placeholder={
              isDragActive ? 'drop your files here' : 'Hit the bolt for ideas!'
            }
            onFocus={() => setCurrIndex(cells.length)}
            value={command}
            onChange={(e) => {
              setCommand(e.target.value);
            }}
            onKeyDown={(e) => {
              handleKeyDown(e, cells.length - 1);
            }}
          />
        ) : (
          <div className='px-3 ml-1 w-full h-12 flex flex-row justify-center	content-center'>
            <span className='loading loading-dots loading-md text-red-700'></span>
          </div>
        )}
        <input
          type='file'
          className='hidden'
          id='fileInput'
          onChange={(e) => {
            e.preventDefault();
            loadFile(e.target.files[0]);
          }}
        />
        {/* {cells.length === 0 && ( */}
        <button
          onClick={addIdea}
          type='button'
          className='text-red-400 hover:text-red-600 h-12 w-10  p-3  focus:outline-none'
        >
          <ZapIcon />
        </button>
        {/* )} */}
        {/* <label
          htmlFor='fileInput'
          className='flex justify-center items-center text-red-400 hover:text-red-600 h-12 cursor-pointer focus:outline-none'
        >
          <UploadIcon />
        </label> */}
        <button
          type='submit'
          className='text-red-400 hover:text-red-600 h-12 w-12 p-3 focus:outline-none'
        >
          <SendIcon />
        </button>
      </form>
    </div>
  );
}
