import React from 'react';
import { LostSVG } from './components/SVGs';

export function LostPage() {
  return (
    // center the div
    <div className='flex flex-row items-center justify-center h-screen w-screen'>
      <div className='flex flex-col h-1/2 w-1/2'>
        <div className=' flex-grow'>
          <h1 className='text-5xl mb-10 font-thin text-gray-700 text-center z-20'>
            <span className='text-gray-700 '>404 - </span>
            we are lost
          </h1>
          <LostSVG />
        </div>
      </div>
    </div>
  );
}
