import React, { useEffect } from 'react';
import Tabs from './Tabs';
import CodeMirror from '@uiw/react-codemirror';
import { AssistantLogo, UserLogo } from './SVGs';

export default function Cell(props) {
  const {
    cell,
    index,
    currIndex,
    setCurrIndex,
    handlePyCodeChange,
    handleJsCodeChange,
    readOnly = true,
    userProfileURL,
    isStreaming,
  } = props;
  const self = this;

  // useeffect for checking if js code changed
  // if changed, then update the tab

  useEffect(() => {
    if (cell.data.jsCode !== '') {
      setCurrTab('js');
    }
  }, [cell.data.jsCode]);

  const [currTab, setCurrTab] = React.useState('py');

  const [showCode, setShowCode] = React.useState(index === currIndex);
  //   cell.data.explanationText === '' || cell.data.explanationText === undefined
  // );

  let explanationText = cell.data.explanationText;

  return (
    <div
      className={`flex flex-row w-full rounded`}
      onClick={() => setCurrIndex(index)}
    >
      <div
        className={`w-12 h-12  text-white items-center justify-center p-1   select-none`}
      >
        {cell.sender === 'AI' ? (
          <AssistantLogo />
        ) : userProfileURL ? (
          <img
            className='h-9 w-9 rounded-full object-cover ml-0.5 mt-0.5  select-none'
            src={userProfileURL}
            alt='user'
          />
        ) : (
          <UserLogo />
        )}
      </div>
      <div
        className={`${
          currIndex === index
            ? ' border border-gray-400   bg-gray-100'
            : 'bg-gray-100 border-gray-100'
        }  rounded-lg pb-2 pt-1 px-4 whitespace-pre-wrap w-[calc(100%-3rem)] flex-col flex justify-center text-left`}
      >
        {cell.type === 'textCell' ||
        (cell.type === 'codeCell' &&
          cell.data.text === '' &&
          cell.data.jsCode === '') ? (
          <div className='text-gray-700 -mb-1'>
            {cell.type === 'codeCell'
              ? cell.data.explanationText.trim()
              : cell.data.text.trim()}
          </div>
        ) : (
          <div
            className={`flex-col flex space-y-2 h-full w-full ${
              showCode ? 'mb-1' : 'mb-1'
            }`}
          >
            {explanationText && (
              <div className='text-gray-700 mt-1 '>
                {explanationText.trim()}
              </div>
            )}
            {!showCode && (
              <span
                className={`text-red-600  hover:underline select-none cursor-pointer mb-1 mt-2 ${
                  currIndex === index && isStreaming ? ' animate-pulse' : ''
                }`}
                onClick={() => setShowCode(true)}
              >
                Show Code
              </span>
            )}

            {showCode && (
              <div className='border border-gray-500 bg-gray-100  px-0 pb-0 pt-0 rounded mt-2 '>
                <Tabs
                  currTab={currTab}
                  setCurrTab={setCurrTab}
                  hideCodeFunc={() => {
                    setShowCode(false);
                  }}
                />
                {currTab === 'py' ? (
                  readOnly ? (
                    <ReadOnlyEditor code={cell.data.text} />
                  ) : (
                    <CodeMirror
                      class='pyEditor'
                      value={cell.data.text}
                      options={{
                        theme: 'material',
                        lineNumbers: true,
                        mode: 'python',
                        base: 'python',
                        lineWrapping: true,
                        readOnly: readOnly,
                        nocursor: readOnly,
                      }}
                      onChange={(value) => {
                        if (!readOnly) {
                          handlePyCodeChange(index, value);
                        }
                      }}
                    />
                  )
                ) : readOnly ? (
                  <ReadOnlyEditor code={cell.data.jsCode} />
                ) : (
                  <CodeMirror
                    class='jsEditor'
                    value={cell.data.jsCode}
                    options={{
                      theme: 'material',
                      lineNumbers: true,
                      mode: 'javascript',
                      readOnly: readOnly,
                      lineWrapping: true,
                      nocursor: readOnly,
                    }}
                    onChange={(value) => {
                      if (!readOnly) {
                        handleJsCodeChange(index, value);
                      }
                    }}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function ReadOnlyEditor(props) {
  const { code } = props;
  // read only editor using react/tailwind
  return (
    <div className=' bg-white rounded-b font-mono text-sm  px-2 py-1 '>
      <span className='break-words	 '>{code.trim()}</span>
    </div>
  );
}
