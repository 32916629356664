import React from 'react';

export function FlowSVG() {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 855 793'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M600.975 500.896C525.472 500.896 457.243 448.517 440.281 371.817C424.578 300.812 469.569 230.27 540.574 214.567C554.128 211.57 568.14 211.271 581.81 213.689C595.479 216.106 608.539 221.192 620.242 228.657C631.946 236.122 642.065 245.818 650.021 257.194C657.977 268.569 663.615 281.4 666.613 294.954C671.441 316.902 667.365 339.87 655.281 358.818C643.196 377.765 624.089 391.146 602.152 396.023C586.995 399.313 571.171 397.352 557.276 390.461C543.382 383.57 532.244 372.159 525.691 358.102C525.635 357.982 525.603 357.853 525.598 357.722C525.592 357.59 525.613 357.459 525.658 357.335C525.704 357.211 525.773 357.098 525.862 357.001C525.952 356.904 526.059 356.826 526.179 356.771C526.299 356.716 526.428 356.685 526.56 356.68C526.691 356.675 526.823 356.696 526.946 356.742C527.07 356.788 527.183 356.858 527.279 356.948C527.375 357.038 527.453 357.146 527.508 357.266C533.867 370.91 544.678 381.985 558.164 388.673C571.65 395.362 587.009 397.265 601.72 394.07C623.14 389.308 641.796 376.243 653.595 357.742C665.395 339.242 669.374 316.816 664.66 295.386C658.721 268.53 642.356 245.134 619.167 230.343C595.977 215.553 567.862 210.581 541.006 216.52C507.392 223.994 478.115 244.497 459.598 273.529C441.081 302.562 434.836 337.754 442.234 371.386C461.595 458.93 548.566 514.402 636.114 495.041C665.038 488.664 692.218 476.043 715.752 458.06C739.286 440.076 758.605 417.167 772.357 390.934C772.418 390.817 772.501 390.714 772.602 390.629C772.703 390.545 772.819 390.482 772.945 390.442C773.07 390.403 773.203 390.389 773.334 390.401C773.465 390.413 773.592 390.45 773.708 390.512C773.825 390.573 773.928 390.656 774.012 390.758C774.096 390.859 774.16 390.976 774.198 391.101C774.237 391.227 774.251 391.359 774.239 391.49C774.227 391.621 774.189 391.748 774.127 391.865C760.241 418.355 740.732 441.49 716.966 459.649C693.201 477.809 665.754 490.555 636.546 496.994C624.866 499.583 612.938 500.892 600.975 500.896Z'
        fill='#E6E6E6'
      />
      <path
        d='M795.552 275.777C795.305 275.776 795.067 275.685 794.884 275.52C794.701 275.355 794.585 275.128 794.559 274.883C793.745 267.324 792.515 259.816 790.873 252.392C764.756 134.299 658.173 50.286 537.442 52.7416C537.176 52.7468 536.92 52.6465 536.729 52.4627C536.538 52.2789 536.427 52.0267 536.422 51.7616C536.417 51.4964 536.517 51.2399 536.701 51.0486C536.884 50.8574 537.136 50.7469 537.402 50.7416C596.661 49.6411 654.475 69.079 701.033 105.756C747.59 142.434 780.023 194.091 792.826 251.961C794.483 259.456 795.726 267.038 796.547 274.67C796.561 274.801 796.55 274.933 796.512 275.059C796.475 275.185 796.414 275.302 796.332 275.404C796.249 275.507 796.147 275.592 796.032 275.654C795.917 275.717 795.79 275.757 795.66 275.771C795.624 275.775 795.588 275.777 795.552 275.777Z'
        fill='#E6E6E6'
      />
      <path
        d='M146.204 357.729C145.988 357.656 145.804 357.512 145.682 357.32C145.559 357.128 145.506 356.9 145.531 356.674C151.772 298.915 173.607 243.941 208.696 197.639C243.786 151.338 290.808 115.453 344.729 93.8274C344.851 93.7775 344.982 93.7521 345.114 93.7528C345.245 93.7536 345.376 93.7803 345.497 93.8317C345.619 93.883 345.729 93.9578 345.821 94.0518C345.914 94.1458 345.987 94.2572 346.036 94.3795C346.085 94.5018 346.11 94.6326 346.108 94.7645C346.107 94.8963 346.079 95.0265 346.027 95.1476C345.975 95.2688 345.899 95.3784 345.805 95.4703C345.71 95.5621 345.598 95.6344 345.476 95.6829C291.891 117.174 245.162 152.835 210.291 198.847C175.42 244.86 153.721 299.491 147.519 356.89C147.503 357.039 147.453 357.183 147.374 357.31C147.295 357.437 147.189 357.545 147.063 357.626C146.936 357.706 146.794 357.757 146.645 357.775C146.496 357.793 146.345 357.777 146.204 357.729Z'
        fill='#E6E6E6'
      />
      <path
        d='M520.422 161.588H364.422C359.914 161.583 355.593 159.79 352.406 156.603C349.219 153.416 347.427 149.095 347.422 144.588V17.5879C347.427 13.0808 349.219 8.75969 352.406 5.57267C355.593 2.38565 359.914 0.592964 364.422 0.587891H520.422C524.929 0.592964 529.25 2.38565 532.437 5.57267C535.624 8.75969 537.416 13.0808 537.422 17.5879V144.588C537.416 149.095 535.624 153.416 532.437 156.603C529.25 159.79 524.929 161.583 520.422 161.588ZM364.422 2.58789C360.445 2.59239 356.632 4.17419 353.82 6.98626C351.008 9.79833 349.426 13.611 349.422 17.5879V144.588C349.426 148.565 351.008 152.377 353.82 155.19C356.632 158.002 360.445 159.583 364.422 159.588H520.422C524.398 159.583 528.211 158.002 531.023 155.19C533.835 152.377 535.417 148.565 535.422 144.588V17.5879C535.417 13.611 533.835 9.79833 531.023 6.98626C528.211 4.17419 524.398 2.59239 520.422 2.58789H364.422Z'
        fill='white'
      />
      <path
        d='M502.69 38.7258H423.959C423.267 38.7399 422.595 38.4907 422.08 38.0286C421.565 37.5666 421.244 36.9261 421.183 36.2368C421.164 35.8799 421.218 35.5229 421.341 35.1875C421.464 34.8521 421.655 34.5454 421.901 34.286C422.146 34.0266 422.442 33.82 422.771 33.6788C423.099 33.5377 423.453 33.4649 423.81 33.4648H503.954C506.594 35.7848 504.985 38.7258 502.689 38.7258L502.69 38.7258Z'
        fill='#CCCCCC'
      />
      <path
        d='M502.69 52.5354H423.959C423.267 52.5494 422.595 52.3002 422.08 51.8382C421.565 51.3761 421.244 50.7357 421.183 50.0463C421.164 49.6895 421.218 49.3325 421.341 48.9971C421.464 48.6617 421.655 48.355 421.901 48.0956C422.146 47.8362 422.442 47.6296 422.771 47.4884C423.099 47.3472 423.453 47.2744 423.81 47.2744H503.954C506.594 49.5944 504.985 52.5354 502.689 52.5354H502.69Z'
        fill='#CCCCCC'
      />
      <path
        d='M406.939 60.399H382.911C382.126 60.3982 381.374 60.0862 380.82 59.5314C380.265 58.9767 379.953 58.2245 379.952 57.44V29.085C379.953 28.3004 380.265 27.5483 380.82 26.9935C381.374 26.4388 382.126 26.1268 382.911 26.126H406.939C407.723 26.127 408.475 26.4391 409.03 26.9938C409.585 27.5485 409.897 28.3005 409.898 29.085V57.44C409.897 58.2245 409.585 58.9767 409.03 59.5314C408.476 60.0862 407.724 60.3982 406.939 60.399Z'
        fill='#F9A826'
      />
      <path
        d='M502.461 80.8118H382.303C381.611 80.8258 380.94 80.5766 380.424 80.1146C379.909 79.6525 379.588 79.0121 379.527 78.3227C379.508 77.9659 379.562 77.6089 379.685 77.2735C379.809 76.9381 379.999 76.6313 380.245 76.3719C380.491 76.1126 380.787 75.906 381.115 75.7648C381.443 75.6236 381.797 75.5508 382.154 75.5508H503.726C506.366 77.8708 504.757 80.8118 502.461 80.8118V80.8118Z'
        fill='#CCCCCC'
      />
      <path
        d='M502.461 94.6262H382.303C381.611 94.6403 380.939 94.3911 380.424 93.929C379.909 93.467 379.588 92.8265 379.527 92.1372C379.508 91.7803 379.562 91.4233 379.685 91.0879C379.808 90.7525 379.999 90.4458 380.245 90.1864C380.49 89.927 380.786 89.7204 381.115 89.5792C381.443 89.4381 381.797 89.3652 382.154 89.3652H503.725C506.366 91.6852 504.757 94.6262 502.461 94.6262V94.6262Z'
        fill='#CCCCCC'
      />
      <path
        d='M502.461 108.431H382.303C381.611 108.445 380.94 108.196 380.424 107.734C379.909 107.272 379.588 106.631 379.527 105.942C379.508 105.585 379.562 105.228 379.685 104.893C379.809 104.557 379.999 104.25 380.245 103.991C380.491 103.732 380.787 103.525 381.115 103.384C381.443 103.243 381.797 103.17 382.154 103.17H503.726C506.366 105.49 504.757 108.431 502.461 108.431V108.431Z'
        fill='#CCCCCC'
      />
      <path
        d='M502.461 122.244H382.303C381.611 122.258 380.94 122.009 380.424 121.547C379.909 121.085 379.589 120.445 379.527 119.755C379.508 119.399 379.562 119.041 379.685 118.706C379.809 118.371 379.999 118.064 380.245 117.805C380.491 117.545 380.787 117.339 381.115 117.197C381.443 117.056 381.797 116.983 382.154 116.983H503.726C506.366 119.303 504.757 122.244 502.461 122.244V122.244Z'
        fill='#CCCCCC'
      />
      <path
        d='M502.461 136.05H382.303C381.611 136.064 380.94 135.815 380.424 135.353C379.909 134.891 379.589 134.25 379.527 133.561C379.508 133.204 379.562 132.847 379.685 132.512C379.809 132.176 379.999 131.87 380.245 131.61C380.491 131.351 380.787 131.144 381.115 131.003C381.443 130.862 381.797 130.789 382.154 130.789H503.726C506.366 133.109 504.757 136.05 502.461 136.05V136.05Z'
        fill='#CCCCCC'
      />
      <path
        d='M840.922 392.398H727.921C724.392 392.395 721.007 390.991 718.511 388.495C716.015 385.999 714.611 382.614 714.607 379.084V287.09C714.611 283.56 716.015 280.176 718.511 277.68C721.007 275.184 724.392 273.78 727.921 273.776H840.922C844.451 273.78 847.836 275.184 850.332 277.68C852.828 280.176 854.232 283.56 854.236 287.09V379.085C854.232 382.614 852.828 385.999 850.332 388.495C847.836 390.991 844.451 392.395 840.922 392.398ZM727.921 275.776C724.922 275.78 722.046 276.973 719.925 279.094C717.804 281.215 716.611 284.091 716.607 287.09V379.085C716.611 382.084 717.804 384.96 719.925 387.081C722.046 389.202 724.922 390.395 727.921 390.399H840.922C843.921 390.395 846.797 389.202 848.918 387.081C851.039 384.96 852.232 382.084 852.236 379.085V287.09C852.232 284.091 851.039 281.215 848.918 279.094C846.797 276.973 843.921 275.78 840.922 275.776L727.921 275.776Z'
        fill='white'
      />
      <path
        d='M828.078 302.403H771.048C770.547 302.413 770.06 302.232 769.687 301.898C769.314 301.563 769.082 301.099 769.037 300.6C769.024 300.341 769.062 300.083 769.152 299.84C769.241 299.597 769.379 299.375 769.557 299.187C769.735 298.999 769.95 298.849 770.187 298.747C770.425 298.645 770.681 298.592 770.94 298.592H828.993C830.905 300.272 829.74 302.403 828.077 302.403L828.078 302.403Z'
        fill='#CCCCCC'
      />
      <path
        d='M828.078 312.406H771.048C770.547 312.416 770.06 312.235 769.687 311.901C769.314 311.566 769.082 311.102 769.037 310.603C769.023 310.344 769.062 310.086 769.152 309.843C769.241 309.6 769.379 309.377 769.557 309.19C769.735 309.002 769.95 308.852 770.187 308.75C770.425 308.647 770.681 308.595 770.94 308.595H828.993C830.905 310.275 829.74 312.406 828.077 312.406L828.078 312.406Z'
        fill='#CCCCCC'
      />
      <path
        d='M758.719 318.101H741.315C740.746 318.101 740.201 317.875 739.8 317.473C739.398 317.071 739.172 316.526 739.171 315.958V295.419C739.172 294.85 739.398 294.306 739.8 293.904C740.201 293.502 740.746 293.276 741.315 293.275H758.719C759.288 293.276 759.832 293.502 760.234 293.904C760.636 294.306 760.862 294.851 760.863 295.419V315.958C760.862 316.526 760.636 317.071 760.234 317.473C759.833 317.875 759.288 318.101 758.719 318.101Z'
        fill='#F9A826'
      />
      <path
        d='M827.912 332.888H740.874C740.373 332.898 739.886 332.718 739.513 332.383C739.14 332.048 738.908 331.584 738.863 331.085C738.85 330.827 738.888 330.568 738.978 330.325C739.067 330.082 739.205 329.86 739.383 329.672C739.561 329.484 739.776 329.334 740.013 329.232C740.251 329.13 740.507 329.077 740.766 329.077H828.828C830.74 330.758 829.575 332.888 827.912 332.888V332.888Z'
        fill='#CCCCCC'
      />
      <path
        d='M827.912 342.894H740.874C740.373 342.904 739.886 342.724 739.513 342.389C739.14 342.054 738.908 341.59 738.863 341.091C738.85 340.832 738.888 340.574 738.978 340.331C739.067 340.088 739.205 339.866 739.383 339.678C739.561 339.49 739.776 339.34 740.013 339.238C740.251 339.136 740.507 339.083 740.766 339.083H828.828C830.74 340.764 829.575 342.894 827.912 342.894V342.894Z'
        fill='#CCCCCC'
      />
      <path
        d='M827.912 352.894H740.874C740.373 352.904 739.886 352.724 739.513 352.389C739.14 352.054 738.908 351.59 738.863 351.091C738.85 350.832 738.888 350.574 738.978 350.331C739.067 350.088 739.205 349.866 739.383 349.678C739.561 349.49 739.776 349.34 740.013 349.238C740.251 349.136 740.507 349.083 740.766 349.083H828.828C830.74 350.764 829.575 352.894 827.912 352.894V352.894Z'
        fill='#CCCCCC'
      />
      <path
        d='M827.912 362.9H740.874C740.373 362.91 739.886 362.729 739.513 362.395C739.14 362.06 738.908 361.596 738.863 361.097C738.85 360.838 738.888 360.58 738.978 360.337C739.067 360.094 739.205 359.872 739.383 359.684C739.561 359.496 739.776 359.346 740.013 359.244C740.251 359.142 740.507 359.089 740.766 359.089H828.828C830.74 360.769 829.575 362.9 827.912 362.9V362.9Z'
        fill='#CCCCCC'
      />
      <path
        d='M827.912 372.9H740.874C740.373 372.91 739.886 372.729 739.513 372.395C739.14 372.06 738.908 371.596 738.863 371.097C738.85 370.838 738.888 370.58 738.978 370.337C739.067 370.094 739.205 369.872 739.383 369.684C739.561 369.496 739.776 369.346 740.013 369.244C740.251 369.142 740.507 369.089 740.766 369.089H828.828C830.74 370.769 829.575 372.9 827.912 372.9V372.9Z'
        fill='#CCCCCC'
      />
      <path
        d='M563.242 359.057H491.601C489.266 359.054 487.027 358.125 485.376 356.474C483.725 354.823 482.796 352.585 482.794 350.25V291.926C482.796 289.591 483.725 287.353 485.376 285.702C487.027 284.05 489.266 283.122 491.601 283.119H563.242C565.577 283.122 567.816 284.05 569.467 285.702C571.118 287.353 572.047 289.591 572.049 291.926V350.25C572.047 352.585 571.118 354.823 569.467 356.474C567.816 358.125 565.577 359.054 563.242 359.057ZM491.601 285.119C489.796 285.121 488.066 285.839 486.79 287.115C485.514 288.391 484.796 290.122 484.794 291.926V350.25C484.796 352.054 485.514 353.784 486.79 355.061C488.066 356.337 489.796 357.055 491.601 357.057H563.242C565.047 357.055 566.777 356.337 568.053 355.061C569.33 353.784 570.047 352.054 570.049 350.25V291.926C570.047 290.122 569.33 288.391 568.053 287.115C566.777 285.839 565.047 285.121 563.242 285.119H491.601Z'
        fill='white'
      />
      <path
        d='M551.922 327.338H502.922C501.728 327.338 500.583 326.864 499.74 326.02C498.896 325.176 498.422 324.031 498.422 322.838C498.422 321.644 498.896 320.5 499.74 319.656C500.583 318.812 501.728 318.338 502.922 318.338H551.922C553.115 318.338 554.26 318.812 555.104 319.656C555.947 320.5 556.422 321.644 556.422 322.838C556.422 324.031 555.947 325.176 555.104 326.02C554.26 326.864 553.115 327.338 551.922 327.338Z'
        fill='#F9A826'
      />
      <path
        d='M541.922 308.838H512.922C511.728 308.838 510.583 308.364 509.74 307.52C508.896 306.676 508.422 305.531 508.422 304.338C508.422 303.144 508.896 302 509.74 301.156C510.583 300.312 511.728 299.838 512.922 299.838H541.922C543.115 299.838 544.26 300.312 545.104 301.156C545.947 302 546.422 303.144 546.422 304.338C546.422 305.531 545.947 306.676 545.104 307.52C544.26 308.364 543.115 308.838 541.922 308.838Z'
        fill='#F9A826'
      />
      <path
        d='M551.922 342.338H502.922C501.728 342.338 500.583 341.864 499.74 341.02C498.896 340.176 498.422 339.031 498.422 337.838C498.422 336.644 498.896 335.5 499.74 334.656C500.583 333.812 501.728 333.338 502.922 333.338H551.922C553.115 333.338 554.26 333.812 555.104 334.656C555.947 335.5 556.422 336.644 556.422 337.838C556.422 339.031 555.947 340.176 555.104 341.02C554.26 341.864 553.115 342.338 551.922 342.338Z'
        fill='#F9A826'
      />
      <path
        d='M224.722 187.933C232.047 190.409 239.992 186.479 242.468 179.154C244.945 171.829 241.014 163.884 233.69 161.408C226.365 158.931 218.42 162.862 215.943 170.186C213.467 177.511 217.397 185.456 224.722 187.933Z'
        fill='#E6E6E6'
      />
      <path
        d='M709.049 130.777C716.781 130.777 723.049 124.509 723.049 116.777C723.049 109.045 716.781 102.777 709.049 102.777C701.318 102.777 695.049 109.045 695.049 116.777C695.049 124.509 701.318 130.777 709.049 130.777Z'
        fill='#E6E6E6'
      />
      <path
        d='M556.049 508.777C563.781 508.777 570.049 502.509 570.049 494.777C570.049 487.045 563.781 480.777 556.049 480.777C548.318 480.777 542.049 487.045 542.049 494.777C542.049 502.509 548.318 508.777 556.049 508.777Z'
        fill='#E6E6E6'
      />
      <path
        d='M231.161 746.768C182.865 746.791 136.029 730.213 98.5025 699.813C60.9755 669.413 35.0379 627.038 25.0364 579.789C15.0349 532.541 21.5774 483.29 43.5675 440.292C65.5575 397.293 101.659 363.159 145.821 343.611C189.983 324.062 239.523 320.287 286.137 332.918C332.752 345.548 373.609 373.817 401.86 412.987C430.112 452.157 444.042 499.848 441.316 548.066C438.59 596.284 419.373 642.101 386.886 677.836L373.498 691.989L357.498 704.644C327.498 722.644 351.789 707.644 323.099 725.649C294.441 739.542 263.008 746.762 231.161 746.768ZM231.161 360.202C134.004 360.202 54.9605 439.245 54.9605 536.402C54.9605 633.559 134.004 712.602 231.161 712.602C260.767 712.605 289.898 705.154 315.866 690.936L325.261 685.774L333.996 691.989C340.362 696.518 325.244 688.011 339.658 690.936V676.22L351.789 664.82C369.41 648.406 383.443 628.524 393.007 606.424C402.571 584.323 407.458 560.483 407.361 536.402C407.361 439.245 328.317 360.202 231.161 360.202Z'
        fill='#424247'
      />
      <path
        d='M424.444 536.401C424.444 429.654 337.908 343.118 231.16 343.118C124.413 343.118 37.8772 429.654 37.8772 536.401C37.8772 643.149 124.413 729.685 231.16 729.685C263.648 729.732 295.619 721.552 324.093 705.908C349.998 690.144 311.299 708.302 343.498 694.644L363.488 677.269C382.755 659.216 398.108 637.397 408.593 613.165C419.079 588.933 424.474 562.805 424.444 536.401Z'
        fill='#F9A826'
      />
      <path
        opacity='0.1'
        d='M72.0434 535.426C72.0434 434.436 149.5 351.552 248.244 342.903C242.613 342.409 236.918 342.143 231.16 342.143C124.413 342.143 37.8772 428.678 37.8772 535.426C37.8772 642.173 124.413 728.709 231.16 728.709C236.91 728.709 242.595 728.439 248.216 727.947C149.486 719.284 72.0434 636.406 72.0434 535.426Z'
        fill='black'
      />
      <path
        d='M21.035 529.648C30.9955 529.648 39.0701 521.574 39.0701 511.613C39.0701 501.653 30.9955 493.578 21.035 493.578C11.0746 493.578 3 501.653 3 511.613C3 521.574 11.0746 529.648 21.035 529.648Z'
        fill='#2F2E41'
      />
      <path
        d='M86.409 565.153L77.325 556.323L59.084 561.989L60.836 583.11L98.342 581.368L86.82 565.712L86.409 565.153Z'
        fill='#FBBEBE'
      />
      <path
        d='M66.5415 564.972C82.6449 564.972 95.6992 551.917 95.6992 535.814C95.6992 519.711 82.6449 506.656 66.5415 506.656C50.4381 506.656 37.3838 519.711 37.3838 535.814C37.3838 551.917 50.4381 564.972 66.5415 564.972Z'
        fill='#FBBEBE'
      />
      <path
        d='M247.554 602.578C247.554 602.578 283.375 664.137 291.9 673.378C295.954 677.927 300.273 682.231 304.835 686.27C304.835 686.27 300.827 699.564 294.444 699.089C290.806 698.87 287.238 697.991 283.915 696.494C283.915 696.494 281.066 689.109 276.096 685.871C271.127 682.632 230.528 642.691 231.477 629.925C232.426 617.159 247.554 602.578 247.554 602.578Z'
        fill='#2F2E41'
      />
      <path
        d='M119.986 690.424C119.986 690.424 158.179 720.068 210.084 692.214L214.557 688.922C214.557 688.922 207.009 705.904 206.308 710.508C205.496 715.846 185.843 747.118 182.635 748.616C179.428 750.114 178.721 751.496 178.721 751.496L192.332 766.477L197.364 762.774C197.364 762.774 216.986 734.973 222.725 729.888C228.464 724.802 255.808 672.356 255.808 672.356C255.808 672.356 266.262 652.595 240.172 651.109C214.081 649.622 202.075 656.734 202.075 656.734L155.771 664.089L119.986 690.424Z'
        fill='#2F2E41'
      />
      <path
        d='M305.278 683.357L324.596 696.724C324.596 696.724 349.242 704.445 334.06 709.584C318.879 714.723 278.873 716.581 277.965 709.492C277.057 702.402 279.543 691.243 281.539 691.853C283.535 692.463 297.387 696.06 305.278 683.357Z'
        fill='#2F2E41'
      />
      <path
        d='M199.073 758.182L202.649 777.817C202.649 777.817 212.816 797.261 200.609 791.228C188.402 785.195 163.252 762.278 166.965 757.474C170.679 752.67 178.876 747.459 179.708 749.025C180.541 750.59 186.702 761.073 199.073 758.182Z'
        fill='#2F2E41'
      />
      <path
        d='M162.238 670.535C162.238 670.535 136.275 700.847 120.513 693.484C104.751 686.121 40.264 600.836 47.6273 585.074C54.9906 569.311 57.4594 572.666 57.4594 572.666L90.756 575.745L81.1757 560.385C81.1757 560.385 85.6488 557.093 89.9113 561.714C94.1738 566.334 111.287 577.875 117.248 594.175C123.21 610.475 140.713 609.662 140.713 609.662L162.238 670.535Z'
        fill='white'
      />
      <path
        d='M267.083 608.031L222.078 655.804L181.762 584.624L220.089 538.317L267.083 608.031Z'
        fill='#3F3D56'
      />
      <path
        d='M263.686 607.514L223.038 649.495L186.204 584.803L219.984 542.274L263.686 607.514Z'
        fill='white'
      />
      <path
        d='M219.873 653.979L222.078 655.805L220.664 658.568L157.733 677.299L157.138 675.143L160.001 672.182L219.873 653.979Z'
        fill='#B3B3B3'
      />
      <path
        d='M222.637 655.394L157.47 675.769L117.745 600.706L181.762 584.625L222.637 655.394Z'
        fill='#D0CDE1'
      />
      <path
        d='M217.731 645.213L214.935 647.27L183.905 593.392L187.112 591.894L217.731 645.213Z'
        fill='#3F3D56'
      />
      <path
        d='M211.316 648.208L183.749 656.428L152.455 601.02L179.727 594.742L211.316 648.208Z'
        fill='#3F3D56'
      />
      <path
        d='M133.655 607.098L139.362 605.484C139.362 605.484 171.192 601.885 171.657 611.886C172.121 621.888 135.09 617.248 135.09 617.248L130.152 610.538L133.655 607.098Z'
        fill='#FBBEBE'
      />
      <path
        d='M161.573 652.062L169.517 648.802C169.517 648.802 193.497 638.05 186.618 631.045C179.739 624.04 158.523 636.205 158.523 636.205L154.461 640.056L161.573 652.062Z'
        fill='#FBBEBE'
      />
      <path
        d='M154.493 636.585C154.493 636.585 167.428 649.477 164.484 652.505C161.541 655.533 113.276 695.363 101.311 682.618C89.3467 669.874 31.0539 605.89 53.7366 600.403C76.4192 594.916 82.4123 607.745 82.4123 607.745L112.356 658.975L154.493 636.585Z'
        fill='white'
      />
      <path
        d='M115.761 597.425L137.009 604.63L136.662 619.97L114.19 617.545L115.761 597.425Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M134.912 644.53L112.019 657.93L85.863 617.703L111.111 650.84L134.912 644.53Z'
        fill='black'
      />
      <path
        d='M53.7433 562.074C72.914 562.074 88.4549 546.533 88.4549 527.362C88.4549 508.191 72.914 492.65 53.7433 492.65C34.5726 492.65 19.0317 508.191 19.0317 527.362C19.0317 546.533 34.5726 562.074 53.7433 562.074Z'
        fill='#2F2E41'
      />
      <path
        d='M241.298 611.061L216.993 575.506L227.296 562.752L252.551 599.332L241.298 611.061Z'
        fill='#F9A826'
      />
      <path
        d='M240.613 625.355L203.611 571.562L205.436 569.357L242.265 623.277L240.613 625.355Z'
        fill='#E6E6E6'
      />
      <path
        d='M235.845 630.589L198.843 576.796L200.668 574.591L237.497 628.512L235.845 630.589Z'
        fill='#E6E6E6'
      />
      <path
        d='M231.783 634.439L194.781 580.646L196.606 578.441L233.435 632.362L231.783 634.439Z'
        fill='#E6E6E6'
      />
      <path
        d='M69.115 504.188H79.853C85.2899 504.187 90.504 506.347 94.3485 510.192C98.1929 514.036 100.353 519.25 100.353 524.687H69.115V504.188Z'
        fill='#2F2E41'
      />
    </svg>
  );
}

export function PlusSVG() {
  const [fill, setFill] = React.useState('#C7C7C7');
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 172 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onMouseOver={(e) => {
        setFill('#878787');
      }}
      onMouseOut={(e) => {
        setFill('#C7C7C7');
      }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M92 8H171.5C171.776 8 172 7.77614 172 7.5C172 7.22386 171.776 7 171.5 7H92V8ZM80 8V7H0.5C0.223858 7 0 7.22386 0 7.5C0 7.77614 0.223858 8 0.5 8H80Z'
        fill={fill}
      />
      <path
        d='M89.1195 7.94399H86.4675V10.739H85.525V7.94399H82.886V7.06649H85.525V4.291H86.4675V7.06649H89.1195V7.94399Z'
        fill={fill}
      />
    </svg>
  );
}

export function LostSVG() {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 1065 799'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_24_2)'>
        <path
          opacity='0.1'
          d='M42.09 186.01C20.8 222.27 35.16 267.53 38.65 309.29C40.7688 334.813 38.5474 360.51 32.08 385.29C22.9 420.47 5.31002 453.47 0.810022 489.47C-3.68998 525.47 10.21 567.89 44.74 580.41C53.31 583.52 62.58 584.57 70.92 588.25C93.54 598.25 103.57 625.01 104.34 649.43C105.11 673.85 99.03 698.14 100.13 722.55C100.75 736.29 104.02 750.64 113.47 760.75C122.16 770.04 134.8 774.49 146.97 778.45C185.45 790.98 225.63 802.06 265.97 798.19C306.31 794.32 347.2 772.5 362.7 735.59C368.31 722.23 370.39 707.59 376.03 694.27C381.67 680.95 392.35 668.16 406.87 666.07C415.35 664.85 423.87 667.43 432.05 669.97L549.31 706.47C567.31 712.08 587.51 717.56 604.41 709.25C613.1 704.97 619.69 697.54 626.03 690.25C676.13 633 728.33 570.91 737.5 495.84C740.73 469.39 738.36 442.48 741.95 416.08C745.54 389.68 756.53 362.33 779.34 348.08C796.93 337.08 818.7 335.43 839.42 333.21C872.96 329.61 906.71 323.51 937.13 309.14C967.55 294.77 994.62 271.32 1007.57 240.62C1011.4 231.54 1013.97 221.49 1011.81 211.9C1007.95 194.79 990.74 184.31 974.61 176.9C926.37 154.9 874.05 143.12 821.94 132.75C798.04 127.99 773.94 123.48 749.52 123.75C728.41 123.97 707.52 127.75 686.73 131.34C508.69 161.95 316.31 181.28 137.62 143.96C100.15 136.13 62.5 151.24 42.09 186.01Z'
          fill='#4a5568'
        />
        <path
          opacity='0.4'
          d='M296.78 115.79C296.78 115.79 512.02 47.26 596.61 97.59C654.79 132.2 785.89 122.25 862.1 112.65C904.255 107.334 947.07 111.986 987.1 126.23C1025.67 139.97 1066.22 156.56 1064.52 165.05C1061.31 181.11 296.78 171.47 296.78 171.47V115.79Z'
          fill='#4a5568'
        />
        <path
          d='M805.43 427.24L804.63 427.31L804.88 426.69L803.88 427.1L804.88 424.47C804.88 424.47 801.63 425.78 795.61 427.92C647.8 434.81 338.51 309.4 338.51 309.4L167.31 332.91C166.315 331.981 165.374 330.996 164.49 329.96L165.25 333.05C164.983 332.757 164.73 332.467 164.49 332.18L164.76 333.26H164.49L164.91 333.84L184.5 413.3C184.5 413.3 533.62 605.66 743.73 581.21L803.54 430.05L805.43 427.24Z'
          fill='url(#paint0_linear_24_2)'
        />
        <path
          d='M343.9 314.47C343.9 314.47 655.51 440.79 793.65 427.94L701.56 565.01L368.56 461.14L205.76 387.25L281.76 335.85L343.9 314.47Z'
          fill='#4a5568'
        />
        <path
          opacity='0.1'
          d='M343.9 314.47C343.9 314.47 655.51 440.79 793.65 427.94L701.56 565.01L368.56 461.14L205.76 387.25L281.76 335.85L343.9 314.47Z'
          fill='black'
        />
        <path
          opacity='0.1'
          d='M331.58 352.45L414.57 371.19L245.38 401.17L205.76 365.83L331.58 352.45Z'
          fill='white'
        />
        <path
          opacity='0.1'
          d='M368.52 429.01L466.51 393.14L535.58 416.16L414.57 461.14L368.52 429.01Z'
          fill='white'
        />
        <path
          opacity='0.1'
          d='M503.45 469.7L611.07 440.26L706.91 466.49L605.18 500.76L503.45 469.7Z'
          fill='white'
        />
        <path
          d='M176.31 337.46L343.9 314.44V355.13L203.62 375.47L176.31 337.46Z'
          fill='#4a5568'
        />
        <path
          opacity='0.1'
          d='M177.38 339.6L343.9 314.44V355.13L203.62 375.47L177.38 339.6Z'
          fill='white'
        />
        <path
          d='M28 203.6C28.0094 187.211 33.5405 171.303 43.7007 158.443C53.8609 145.583 68.0576 136.521 84 132.72C153.73 115.99 270.67 95.72 327.87 129.72C412.47 180.05 651.26 136.14 651.26 136.14C651.26 136.14 799.03 181.14 795.82 197.14C792.61 213.14 28.03 203.56 28.03 203.56L28 203.6Z'
          fill='#4a5568'
        />
        <path
          d='M618.73 146.57C620.57 144.86 622.3 142.84 622.83 140.38C623.36 137.92 622.31 134.97 619.96 134.07C617.33 133.07 614.51 134.89 612.38 136.73C610.25 138.57 607.8 140.68 605.01 140.29C606.447 138.987 607.522 137.334 608.13 135.492C608.738 133.65 608.858 131.682 608.48 129.78C608.347 128.992 608.001 128.256 607.48 127.65C606.02 126.09 603.37 126.76 601.62 127.99C596.05 131.91 594.5 139.47 594.47 146.28C593.91 143.82 594.38 141.28 594.37 138.75C594.36 136.22 593.67 133.44 591.54 132.09C590.216 131.383 588.73 131.038 587.23 131.09C584.73 131 581.94 131.25 580.23 133.09C578.11 135.36 578.66 139.18 580.5 141.69C582.34 144.2 585.16 145.76 587.75 147.49C589.826 148.726 591.597 150.415 592.93 152.43C593.085 152.71 593.212 153.005 593.31 153.31H609C612.534 151.515 615.807 149.247 618.73 146.57Z'
          fill='#4a5568'
        />
        <path
          d='M273.93 112.3C275.77 110.59 277.5 108.57 278.03 106.11C278.56 103.65 277.51 100.7 275.16 99.8C272.53 98.8 269.71 100.62 267.58 102.46C265.45 104.3 263 106.41 260.21 106.02C261.653 104.713 262.732 103.054 263.34 101.204C263.949 99.3544 264.066 97.3784 263.68 95.47C263.547 94.6822 263.201 93.9458 262.68 93.34C261.22 91.78 258.57 92.45 256.82 93.68C251.25 97.6 249.7 105.16 249.67 111.97C249.11 109.51 249.58 106.97 249.57 104.44C249.56 101.91 248.87 99.13 246.74 97.78C245.416 97.0729 243.93 96.728 242.43 96.78C239.93 96.69 237.14 96.94 235.43 98.78C233.31 101.05 233.86 104.87 235.7 107.38C237.54 109.89 240.36 111.45 242.95 113.18C245.026 114.416 246.797 116.105 248.13 118.12C248.285 118.4 248.412 118.695 248.51 119H264.2C267.731 117.217 271.005 114.963 273.93 112.3Z'
          fill='#4a5568'
        />
        <path
          d='M785.78 186.19C787.62 184.48 789.35 182.46 789.88 180C790.41 177.54 789.36 174.59 787.01 173.69C784.38 172.69 781.56 174.51 779.43 176.35C777.3 178.19 774.85 180.3 772.06 179.91C773.497 178.607 774.572 176.954 775.18 175.112C775.788 173.27 775.908 171.302 775.53 169.4C775.397 168.612 775.051 167.876 774.53 167.27C773.07 165.71 770.42 166.38 768.67 167.61C763.1 171.53 761.55 179.09 761.52 185.9C760.96 183.44 761.43 180.9 761.42 178.37C761.41 175.84 760.72 173.06 758.59 171.71C757.266 171.003 755.78 170.658 754.28 170.71C751.78 170.62 748.99 170.87 747.28 172.71C745.16 174.98 745.71 178.8 747.55 181.31C749.39 183.82 752.21 185.38 754.8 187.11C756.876 188.346 758.647 190.035 759.98 192.05C760.135 192.33 760.262 192.625 760.36 192.93H776.05C779.584 191.135 782.857 188.867 785.78 186.19Z'
          fill='#4a5568'
        />
        <path
          opacity='0.1'
          d='M176.31 334.24L195.58 412.41C195.58 412.41 531.83 597.67 734.22 574.11L793.12 425.26C793.12 425.26 647.96 483.94 493.92 456.96C488.017 455.914 482.18 454.521 476.44 452.79C430.41 439.01 207.02 370.71 176.31 334.24Z'
          fill='black'
        />
        <path
          d='M176.31 336.38L195.58 414.55C195.58 414.55 531.82 599.8 734.21 576.25L793.11 427.4C793.11 427.4 647.95 486.08 493.91 459.1C488.007 458.054 482.17 456.661 476.43 454.93C430.41 441.15 207.02 372.85 176.31 336.38Z'
          fill='#4a5568'
        />
        <path
          opacity='0.1'
          d='M158.88 58.47H151.51C152.878 58.47 154.191 57.9264 155.159 56.9587C156.126 55.991 156.67 54.6785 156.67 53.31C156.67 51.9415 156.126 50.629 155.159 49.6613C154.191 48.6936 152.878 48.15 151.51 48.15H91.83C91.1524 48.15 90.4814 48.2835 89.8553 48.5428C89.2293 48.8021 88.6604 49.1822 88.1813 49.6613C87.7021 50.1405 87.3221 50.7093 87.0627 51.3354C86.8034 51.9614 86.67 52.6324 86.67 53.31C86.67 53.9876 86.8034 54.6586 87.0627 55.2846C87.3221 55.9107 87.7021 56.4795 88.1813 56.9587C88.6604 57.4378 89.2293 57.8179 89.8553 58.0772C90.4814 58.3365 91.1524 58.47 91.83 58.47H99.2C97.8314 58.47 96.519 59.0136 95.5513 59.9813C94.5836 60.949 94.04 62.2615 94.04 63.63C94.04 64.9985 94.5836 66.311 95.5513 67.2787C96.519 68.2464 97.8314 68.79 99.2 68.79H88.88C87.5115 68.79 86.199 69.3336 85.2313 70.3013C84.2636 71.269 83.72 72.5815 83.72 73.95C83.72 75.3185 84.2636 76.631 85.2313 77.5987C86.199 78.5664 87.5115 79.11 88.88 79.11H148.56C149.928 79.11 151.241 78.5664 152.209 77.5987C153.176 76.631 153.72 75.3185 153.72 73.95C153.72 72.5815 153.176 71.269 152.209 70.3013C151.241 69.3336 149.928 68.79 148.56 68.79H158.88C160.248 68.79 161.561 68.2464 162.529 67.2787C163.496 66.311 164.04 64.9985 164.04 63.63C164.04 62.2615 163.496 60.949 162.529 59.9813C161.561 59.0136 160.248 58.47 158.88 58.47Z'
          fill='#4a5568'
        />
        <path
          opacity='0.1'
          d='M566.86 10.31H559.49C560.859 10.31 562.171 9.76635 563.139 8.79866C564.106 7.83097 564.65 6.51851 564.65 5.14999C564.65 3.78147 564.106 2.46901 563.139 1.50132C562.171 0.533632 560.859 -0.0100098 559.49 -0.0100098H499.81C498.441 -0.0100098 497.129 0.533632 496.161 1.50132C495.194 2.46901 494.65 3.78147 494.65 5.14999C494.65 6.51851 495.194 7.83097 496.161 8.79866C497.129 9.76635 498.441 10.31 499.81 10.31H507.18C506.502 10.31 505.831 10.4435 505.205 10.7028C504.579 10.9621 504.01 11.3422 503.531 11.8213C503.052 12.3005 502.672 12.8693 502.413 13.4953C502.153 14.1214 502.02 14.7924 502.02 15.47C502.02 16.1476 502.153 16.8186 502.413 17.4446C502.672 18.0707 503.052 18.6395 503.531 19.1187C504.01 19.5978 504.579 19.9779 505.205 20.2372C505.831 20.4965 506.502 20.63 507.18 20.63H496.87C496.192 20.63 495.521 20.7635 494.895 21.0228C494.269 21.2821 493.7 21.6622 493.221 22.1413C492.742 22.6205 492.362 23.1893 492.103 23.8153C491.843 24.4414 491.71 25.1124 491.71 25.79C491.71 26.4676 491.843 27.1386 492.103 27.7646C492.362 28.3907 492.742 28.9595 493.221 29.4387C493.7 29.9178 494.269 30.2979 494.895 30.5572C495.521 30.8165 496.192 30.95 496.87 30.95H556.55C557.919 30.95 559.231 30.4064 560.199 29.4387C561.166 28.471 561.71 27.1585 561.71 25.79C561.71 24.4215 561.166 23.109 560.199 22.1413C559.231 21.1736 557.919 20.63 556.55 20.63H566.87C568.239 20.63 569.551 20.0864 570.519 19.1187C571.486 18.151 572.03 16.8385 572.03 15.47C572.03 14.1015 571.486 12.789 570.519 11.8213C569.551 10.8536 568.239 10.31 566.87 10.31H566.86Z'
          fill='#4a5568'
        />
        <path
          opacity='0.1'
          d='M781.03 89.55H773.66C774.338 89.55 775.009 89.4165 775.635 89.1572C776.261 88.8979 776.83 88.5178 777.309 88.0387C777.788 87.5595 778.168 86.9907 778.427 86.3647C778.687 85.7386 778.82 85.0676 778.82 84.39C778.82 83.7124 778.687 83.0414 778.427 82.4154C778.168 81.7893 777.788 81.2205 777.309 80.7413C776.83 80.2622 776.261 79.8821 775.635 79.6228C775.009 79.3635 774.338 79.23 773.66 79.23H713.98C713.302 79.23 712.631 79.3635 712.005 79.6228C711.379 79.8821 710.81 80.2622 710.331 80.7413C709.852 81.2205 709.472 81.7893 709.213 82.4154C708.953 83.0414 708.82 83.7124 708.82 84.39C708.82 85.0676 708.953 85.7386 709.213 86.3647C709.472 86.9907 709.852 87.5595 710.331 88.0387C710.81 88.5178 711.379 88.8979 712.005 89.1572C712.631 89.4165 713.302 89.55 713.98 89.55H721.31C719.941 89.55 718.629 90.0936 717.661 91.0613C716.694 92.029 716.15 93.3415 716.15 94.71C716.15 96.0785 716.694 97.391 717.661 98.3587C718.629 99.3264 719.941 99.87 721.31 99.87H711.03C709.661 99.87 708.349 100.414 707.381 101.381C706.414 102.349 705.87 103.661 705.87 105.03C705.87 106.399 706.414 107.711 707.381 108.679C708.349 109.646 709.661 110.19 711.03 110.19H770.71C772.079 110.19 773.391 109.646 774.359 108.679C775.326 107.711 775.87 106.399 775.87 105.03C775.87 103.661 775.326 102.349 774.359 101.381C773.391 100.414 772.079 99.87 770.71 99.87H781.03C782.398 99.87 783.711 99.3264 784.679 98.3587C785.646 97.391 786.19 96.0785 786.19 94.71C786.19 93.3415 785.646 92.029 784.679 91.0613C783.711 90.0936 782.398 89.55 781.03 89.55Z'
          fill='#4a5568'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_24_2'
          x1='484.98'
          y1='583.35'
          x2='484.98'
          y2='309.4'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#808080' stop-opacity='0.25' />
          <stop offset='0.54' stop-color='#808080' stop-opacity='0.12' />
          <stop offset='1' stop-color='#808080' stop-opacity='0.1' />
        </linearGradient>
        <clipPath id='clip0_24_2'>
          <rect width='1064.62' height='798.95' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export function AssistantLogo() {
  // return an image located in public/BN-logo.png
  return (
    <img
      src='/BN-sphere.png'
      alt='BN logo'
      className=' object-contain p-0.5'
    ></img>
  );
}

export function SendIcon() {
  return (
    <svg
      height='100%'
      width='100%'
      viewBox='0 0 16 16'
      fill='none'
      strokeWidth='2'
    >
      <path
        d='M.5 1.163A1 1 0 0 1 1.97.28l12.868 6.837a1 1 0 0 1 0 1.766L1.969 15.72A1 1 0 0 1 .5 14.836V10.33a1 1 0 0 1 .816-.983L8.5 8 1.316 6.653A1 1 0 0 1 .5 5.67V1.163Z'
        fill='currentColor'
      ></path>
    </svg>
  );
}
export function UserLogo() {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 93 93'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='46.5' cy='46.5' r='46.5' fill='#FCBCEB' fillOpacity='0.2' />
      <path
        d='M46.5793 57.3986C55.7704 57.3986 63.2213 49.9477 63.2213 40.7566C63.2213 31.5654 55.7704 24.1145 46.5793 24.1145C37.3881 24.1145 29.9372 31.5654 29.9372 40.7566C29.9372 49.9477 37.3881 57.3986 46.5793 57.3986Z'
        fill='#FDB5BF'
      />
      <path
        d='M32.8046 61.1251L36.2738 52.2595L54.1977 49.5613L56.1235 69.1864L38.0068 69.5719L32.8046 61.1251Z'
        fill='#FDB5BF'
      />
      <path
        d='M63.3825 43.8269C63.1666 44.6922 62.73 46.7525 62.0481 46.7626C61.4911 46.7707 60.9579 37.8325 61.5143 36.3541C62.1535 34.6556 60.4738 32.5071 59.2085 31.4041C56.9335 29.4211 51.8834 33.6793 46.7482 31.7503C46.6121 31.6992 46.6392 31.705 45.0177 31.058C40.0279 29.0672 39.7656 29.0097 39.4798 28.9813C37.0609 28.742 34.1089 30.7214 32.9035 33.1347C31.6505 35.6434 32.6974 37.7952 31.5191 43.8644C31.3817 44.5718 31.1661 45.5801 30.8268 45.595C30.2066 45.622 29.601 42.3092 29.4423 41.4416C28.7778 37.8063 28.1371 34.3015 29.4423 30.3658C29.5847 29.9365 30.4622 28.3432 32.2113 25.174C34.7311 20.6086 35.9985 18.3172 36.3647 17.9055C38.9913 14.9517 43.7724 12.2571 47.7866 13.406C49.8155 13.9867 49.4517 14.9203 51.94 15.8288C56.3744 17.4479 60.2283 15.4678 60.9391 16.8672C61.3156 17.6084 60.2621 18.2195 60.2468 19.6361C60.2206 22.0667 63.2947 22.7691 65.0925 25.174C66.0589 26.4669 67.7132 26.4808 63.3825 43.8269Z'
        fill='#151515'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M46.0919 92.9982C51.5732 92.9535 56.919 92.0538 61.9764 90.3624C70.6789 87.2918 78.1939 81.7046 83.6471 74.4749C82.6778 71.1382 81.3669 68.38 79.5922 67.0317C73.2996 62.2499 57.3492 58.7461 55.0519 58.2585C54.8707 58.2199 54.7743 58.2007 54.7743 58.2007C54.7743 58.2007 56.3162 68.0299 48.3622 68.1167C36.6847 68.2458 33.9595 58.2007 33.9595 58.2007C33.9595 58.2007 33.9556 58.2026 33.9479 58.2044L33.947 58.2048C33.3742 58.4332 12.2205 66.8701 10.6121 74.9065C10.5505 75.2139 10.491 75.5297 10.4334 75.8528C18.8801 86.219 31.7068 92.8746 46.0919 92.9982Z'
        fill='#CA64AE'
      />
    </svg>
  );
}

export function UploadIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='currentColor'
      className='w-6 h-6'
    >
      <path
        fillRule='evenodd'
        d='M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm6.905 9.97a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.72-1.72V18a.75.75 0 001.5 0v-4.19l1.72 1.72a.75.75 0 101.06-1.06l-3-3z'
        clipRule='evenodd'
      />
      <path d='M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z' />
    </svg>
  );
}

export function ZapIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='currentColor'
      class='w-6 h-6'
    >
      <path
        fill-rule='evenodd'
        d='M14.615 1.595a.75.75 0 01.359.852L12.982 9.75h7.268a.75.75 0 01.548 1.262l-10.5 11.25a.75.75 0 01-1.272-.71l1.992-7.302H3.75a.75.75 0 01-.548-1.262l10.5-11.25a.75.75 0 01.913-.143z'
        clip-rule='evenodd'
      />
    </svg>
  );
}
