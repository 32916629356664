import React from 'react';
import { MalleableDiv } from './MalleableDiv';
import { ErrorBoundary } from 'react-error-boundary';

const careAboutErrors = false;

export function buildingFallbackRender({ error, resetErrorBoundary }) {
  return (
    <div className='border-2 border-gray-500 p-2 rounded text-gray-500 animate-pulse'>
      Building
    </div>
  );
}

export function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre className='text-red-500 whitespace-pre-wrap'>{error.message}</pre>
    </div>
  );
}

function hasFuncFormat(jsCode) {
  console.log('hasFuncFormat', jsCode);
  let startsRight = jsCode.startsWith('function UserDiv() {');
  let endsRight = jsCode.endsWith('}');
  return startsRight && endsRight && checkIfAllCurlyBracesAreClosed(jsCode);
}

function hasRightStart(jsCode) {
  return jsCode.startsWith('function UserDiv() {');
}

function hasReturn(jsCode) {
  const hasSomeTagAfterReturnAtAnyDistance = /return\s*\((.|\n)*<(\w+)[^>]*>/g;
  return jsCode.includes('return (');
}

function removeIncompleteTag(str) {
  const newStr = str.replace(
    /<[^>]*(=>)?[^>]*$/,
    '<div className="border-2 border-gray-800 p-2 rounded text-gray-800 animate-pulse">building...</div>'
  );
  return newStr.trim() === ''
    ? '<div className="text-gray-500 animate-pulse">building...</div>'
    : newStr;
}
function closeHTMLTags(htmlCode) {
  let stack = [];
  let regex = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
  let match;

  while ((match = regex.exec(htmlCode))) {
    if (match[0].startsWith('</')) {
      if (stack.length > 0 && stack[stack.length - 1] === match[1]) {
        stack.pop();
      }
    } else if (!match[0].endsWith('/>') && !match[0].endsWith('=>')) {
      stack.push(match[1]);
    }
  }

  while (stack.length > 0) {
    htmlCode += `</${stack.pop()}>`;
  }

  return htmlCode;
}

function isCompleteReturnStatement(str) {
  if (str === undefined) return false;
  let openParenthesis = 0;
  let closeParenthesis = 0;

  for (let char of str) {
    if (char === '(') openParenthesis++;
    if (char === ')') closeParenthesis++;
  }

  return openParenthesis === closeParenthesis && openParenthesis > 0;
}

function checkIfAllCurlyBracesAreClosed(str) {
  let openCurlyBraces = 0;
  let closeCurlyBraces = 0;

  for (let char of str) {
    if (char === '{') openCurlyBraces++;
    if (char === '}') closeCurlyBraces++;
  }

  return openCurlyBraces === closeCurlyBraces;
}

export default function OutputBox(props) {
  const { cell, addCell, bookNoteId, noFrame, askGPT, isStreaming } = props;
  console.log('cell', cell);
  let jsCode = cell.data.jsCode;
  if (jsCode !== undefined && jsCode.endsWith('`')) {
    jsCode = jsCode.slice(0, -1);
  }

  let fixedJsCode = '';
  let condition = -1;
  if (hasFuncFormat(String(jsCode))) {
    fixedJsCode = jsCode;
    condition = 1;
  } else if (isCompleteReturnStatement(String(jsCode).split('return')[1])) {
    fixedJsCode = jsCode + ';}';
    condition = 2;
  } else if (hasRightStart(String(jsCode)) && hasReturn(String(jsCode))) {
    const code = jsCode;
    const closedTagsVersion = closeHTMLTags(
      removeIncompleteTag(String(code.split('return (')[1]))
    );
    fixedJsCode =
      code.split('return (')[0] + 'return (' + closedTagsVersion + ');}';
    condition = 3;
  } else {
    fixedJsCode = `function UserDiv() {/* ${jsCode} */ return(<span className="text-gray-500 hover:animate-pulse"></span>)}`;
    condition = 4;
  }

  console.log('formatted string', condition, fixedJsCode);
  return (
    <div
      id='main-content'
      className={`${
        noFrame ? '' : 'py-2'
      } h-full w-full  items-center justify-center flex `}
    >
      <div
        className={`${
          noFrame
            ? ' flex flex-col w-full h-full min-w-full max-h-full '
            : 'bg-gray-50 border border-gray-300 flex flex-col w-1/2  p-2 rounded-lg min-w-full max-h-full whitespace-pre-wrap'
        }  `}
      >
        <div
          className={`${
            noFrame ? '' : 'p-2'
          } w-full h-full max-h-full overflow-y-scroll whitespace-pre-wrap`}
        >
          {cell.type === 'textCell' ? (
            cell.data.text
          ) : (
            <ErrorBoundary
              fallbackRender={
                isStreaming ? buildingFallbackRender : fallbackRender
              }
              resetKeys={[cell.data.jsCode]}
            >
              <MalleableDiv
                reactCode={fixedJsCode}
                addCell={addCell}
                askGPT={askGPT}
                bookNoteId={bookNoteId}
              />
            </ErrorBoundary>
          )}
        </div>
      </div>
    </div>
  );
}
